import "./App.css";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import history from "./history";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Home from "./ui-components/layouts/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.min.css";
import { Provider } from "react-redux";
import store from "./store";
import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import "@ant-design/flowchart/dist/index.css";
import AdminDashboard from "./ui-components/dashboard/AdminDashboard";
import { routes } from "./util/constants";

import { fetchToken, onMessageListener } from "./Firebase";

function App() {
  const [currentUser, setCurrentUser] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const [isLogged, setIsLogged] = useState(false);
  const updateCurrentUser = async (newUser) => {
    if (newUser) {
      await setCurrentUser(newUser);
    }
    try {
      const user = await Auth.currentAuthenticatedUser();

      setIsLogged(true);
      setCurrentUser(user);
      setIsLoaded(false);
      localStorage.setItem("current_user", user);
    } catch (err) {
      console.log("err", err);
      setIsLogged(false);
      console.log("LoggedIn line Catch", isLogged);
      setCurrentUser(null);
      navigate(routes.LOGIN);
      setIsLoaded(true);
    }
  };

  const onHubAuth = (data) => {
    const { payload } = data;
    if (payload.event !== "signIn") {
      updateCurrentUser();
    }
  };

  useEffect(() => {
    updateCurrentUser();
    Hub.listen("auth", onHubAuth);
  }, []);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);
  onMessageListener()
    .then(async (payload) => {
      console.log(payload);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setShow(true);
      let data = JSON.parse(payload.notification.body);
      console.log("the payloadd===========", JSON.parse(data.data.body));
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Provider store={store}>
      <AmplifyProvider>
        <Routes location={location}>
          <Route
            exact
            path={routes.LOGIN}
            element={
              isLogged ? <Navigate to={routes.ADMIN_DASHBOARD} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.ADMIN_DASHBOARD}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />

          <Route
            exact
            path={routes.OPPORTUNITY}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.ESTIMATION}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.OPPORTUNITY_DETAILS}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.TASK_ESTIMATION}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />

          <Route
            exact
            path={routes.QUESTIONNAIRE}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.CONTRIBUTORS}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.DOCUMENTS}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.BTG_PROPOSAL_FORM}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.SUBMIT_ESTIMATION}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.TASK_ESTIMATION_THROUGH_BTG}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.SALES_PROPOSAL_FORM}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.QNA_THROUGH_SALES_PROPOSAL}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />

          <Route
            exact
            path={routes.QNA_THROUGH_BTG_PROPOSAL}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.TASK_ESTIMATION_THROUGH_SALES}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.BTG_PROPOSAL_THROUGH_DETAILS}
            element={
              isLogged ? (
                <AdminDashboard location={location} history={history} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
        </Routes>
      </AmplifyProvider>
    </Provider>
  );
}

export default App;
