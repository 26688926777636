const awsConfig = {
  Auth: {
    identityPoolId: "ap-south-1:c031355c-bde6-4439-be3c-9098e6347950",
    region: "ap-south-1",
    userPoolId: "ap-south-1_CpcDRkdLn",
    userPoolWebClientId: "5aikoaqjg4necgijavjq6ttk36",
  },
  oauth: {
    domain: "yash.auth.ap-south-1.amazoncognito.com",
    scope: ["email", "openid"],
    redirectSignIn:
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://rfpopportunity.yashaccelerator.link",
    redirectSignOut:
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://rfpopportunity.yashaccelerator.link",
    responseType: "code",
  },
};

export default awsConfig;
