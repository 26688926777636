import React from "react";
import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";

const BreadCrumbs = (props) => {
  let location = props.location;
  const navigate = useNavigate();
  const loc = location;
  const pathname = loc.pathname;

  const pathnames = pathname.split("/").filter((item) => item);
  if (props.location.state?.moduleName) {
    let moduleName = props.location.state.moduleName;
    pathnames.pop();
    pathnames.push(moduleName);
  }
  if (props.location.state?.module_name && pathnames.includes("charts")) {
    pathnames.pop();
    pathnames.pop();
    pathnames.push(`${props.location.state.module_name}-Charts`);
  }
  const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
  return (
    <div>
      <Breadcrumb>
        {/* {pathnames.length > 0 ? (
          <Breadcrumb.Item>
            <b>
              <Link to="/organization">Home</Link>
            </b>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        )} */}

        {pathnames.map((name, index) => {
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Breadcrumb.Item> {capatilize(name)}</Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item>
              <label
                style={{ fontWeight: "600" }}
                type="link"
                pointer="cursor"
                onClick={() => {
                  let current_index = pathnames.length - 1;
                  let desired_index = pathnames.indexOf(name.toLowerCase());
                  let navigate_index = current_index - desired_index;
                  navigate(-navigate_index);
                }}
              >
                {capatilize(name)}
              </label>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumbs;
