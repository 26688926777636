import { Col, Row, Form, Input } from "antd";
import React, { useEffect, useState } from "react";

const AddQuestion = (props) => {
  useEffect(() => {
    console.log("props inside add", props);
    if (props.pastQnaArray.length > 0) {
      console.log("entered if ======");
      props.doAfterDisablingPage(props.pastQnaArray.length);
      let pastArray = props.pastQnaArray;
      console.log("past qna array", props.pastQnaArray);
      pastArray.map((ele) => {
        props.reference.current.setFieldValue(ele.questionId, ele.answer);
      });
    }
  }, [props.pastQnaArray]);

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <h4 className="que-name">
          {props.index + 1} {props.question.question} ?
        </h4>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Row className="d-flex align-items-baseline px-3" gutter={[8, 16]}>
          <Form.Item
            value="Avani"
            name={props.question.id}
            style={{
              alignItems: "baseline",
            }}
            rules={[
              {
                required: true,
                message: "* Missing Answer",
              },
            ]}
          >
            <Input
              disabled={props.disabled}
              placeholder="Your Answer"
              name={"question" + props.question.id}
              id={"question" + props.question.id}
              style={{
                alignItems: "baseline",
                margin: "0 300px 0 0px ",
              }}
            />
          </Form.Item>
        </Row>
      </Col>
    </>
  );
};

export default AddQuestion;
