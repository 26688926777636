import axios from "axios";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import { Row, Col, Table, Popconfirm, Button, Space, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  DeleteTwoTone,
  DownloadOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import AddDocumentForm from "../Forms/AddDocumentForm";
const Documents = (props) => {
  const [allDocuments, setAllDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  {
    console.log("record inside add document form", props.location);
  }
  const opportunityId = props.location.state.record.opportunityId;
  const record = props.location.state.record;
  useEffect(() => {
    getAllDocuments();
  }, []);
  const getAllDocuments = async () => {
    setLoading(true);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/document/getalldocumentsbyopportunityid";
    let params = {
      opportunityId: opportunityId,
    };
    console.log("paramssss", params);
    try {
      let res = await axios.get(url, { params });
      console.log("result from get all documents", res);
      setAllDocuments(res.data);
      setLoading(false);
    } catch (error) {
      console.log("error from get all documents", error);
    }
  };

  const downloadDocument = async (record) => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/document/download";
    const params = {
      key: record.url,
    };
    try {
      let res = await axios.get(url, { params });
      console.log("response from download doc", res);
      let downloadUrl = res.data.url;
      window.open(`${downloadUrl}`);
    } catch (err) {
      console.log("error while downloading doc", err);
    }
  };

  const getLatestList = (data) => {
    setAllDocuments(data);
    setModalOpen(false);
  };

  const showAddDocumentModal = () => {
    setModalOpen(true);
  };

  const handleModalCancel = () => {
    setModalOpen(false);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      width: "35%",
      render: (record) => <>{moment(record).format("MMMM Do YYYY, h:mm a")}</>,
    },
    {
      title: "Title",
      dataIndex: "name",
      width: "28%",
    },
    {
      title: "Uploaded By",
      dataIndex: "uploadedBy",
      width: "28%",
    },
    {
      title: "Comment ",
      dataIndex: "comment",
      width: "12%",
    },
    {
      title: "Docs",
      align: "center",
      key: "action",
      width: "10%",
      render: (record) => (
        <>
          {/* <button
            type="button"
            // className="rounded btn-success"
            onClick={() => downloadDocument(record)}
          > */}
          <DownloadOutlined onClick={() => downloadDocument(record)} />
          {/* </button> */}
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      align: "center",
      width: "10%",
      render: (record) => (
        <>
          <Popconfirm
            placement="left"
            title="Are you sure, you want to delete the document？"
            onConfirm={() => {
              // callDeleteDocument(record);
            }}
            icon={<QuestionCircleOutlined className="QuestionCircle-class " />}
          >
            <DeleteTwoTone
              twoToneColor="#d91d0f"
              style={{ fontSize: "18px" }}
            />
          </Popconfirm>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="card-body">
        <h1 className="page-title">Documents</h1>
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <div className="d-flex  justify-content-end mx-0">
          <Button type="primary " className=" " onClick={showAddDocumentModal}>
            <Space>Add Document</Space>
          </Button>
          <Modal
            open={isModalOpen}
            // onOk={handleOk}
            onCancel={handleModalCancel}
            title="Add Document"
            style={{ top: 20 }}
            width={650}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <AddDocumentForm record={record} getLatestList={getLatestList} />
          </Modal>
        </div>

        <div className="  justify-content-end mx-5">
          <div className="card  mx-20 my-3 pt-20 ">
            {/* {pageLoading && (
                  <div className="d-flex justify-content-center">
                    <Spin size="medium" tip="Loading..."></Spin>
                  </div>
                )} */}
            {/* {!pageLoading && ( */}
            <Table
              scroll={{ y: 400 }}
              loading={loading}
              columns={columns}
              dataSource={allDocuments}
              className="table-striped-rows"
            />
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
