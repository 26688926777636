import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { Col, DatePicker, Empty, Row, Spin } from "antd";
import { Line, Column } from "@ant-design/plots";
import { LoadingOutlined } from "@ant-design/icons";

const OpportunityWeeklyCount = () => {
  const monthFormat = "YYYY-MM";
  const defaultMonth = moment(new Date()).month() + 1;
  const defaultYear = moment(new Date()).year();
  var initalMonth = defaultYear + "-" + defaultMonth;
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [chartData, setChartData] = useState([]);
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const handleMonthChange = async (data, dateString) => {
    console.log("inside handle month change", dateString);
    setLoading(true);
    // setPieData([]);
    // setSelectedYear(dateString.slice(0, 4));
    // setSelectedMonth(dateString.slice(5, 8));
    let monthNumber = dateString.slice(5, 8);
    console.log("month number inside month change", typeof monthNumber);
    if (monthNumber === "01") setSelectedMonth("January");
    if (monthNumber === "02") setSelectedMonth("February");
    if (monthNumber === "03") setSelectedMonth("March");
    if (monthNumber === "04") setSelectedMonth("April");
    if (monthNumber === "05") setSelectedMonth("May");
    if (monthNumber === "06") setSelectedMonth("June");
    if (monthNumber === "07") setSelectedMonth("July");
    if (monthNumber === "08") setSelectedMonth("August");
    if (monthNumber === "09") setSelectedMonth("September");
    if (monthNumber === "10") setSelectedMonth("October");
    if (monthNumber === "11") setSelectedMonth("November");
    if (monthNumber === "12") setSelectedMonth("December");
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  const getAllMonthData = async () => {
    console.log("inside get all month data");
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/dashboard/weeklyopportunity";
    const params = {
      month: selectedMonth,
    };
    axios
      .get(url, { params })
      .then((res) => {
        console.log("response from weekly data", res);

        let data = [];
        Object.keys(res.data[0]).map((key) => {
          let obj = {};

          obj["Week"] = "week" + key;
          obj["Count"] = res.data[0][key];
          data.push(obj);
        });
        console.log("final obj for chart", data);

        setChartData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from weekly count", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllMonthData();
  }, [selectedMonth]);

  const config = {
    data: chartData,
    xField: "Week",
    yField: "Count",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    columnWidthRatio: 0.1,
    color: ({ type }) => {
      return "#3d5170";
    },
  };

  return (
    <div className="justify-content-end mx-5">
      {/* <div className="card container-dash-g pt-20 my-3"> */}
      <div className="card container-dash-g">
        <div className="dash-graph-line card-body">
          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="d-flex  mb-2">
                <h5 className="text-secondary" style={{ fontSize: "2.1rem" }}>
                  Total Opportunities By Month
                </h5>
                <h6
                  className="text-secondary mt-3 ms-4"
                  style={{ fontSize: "1.2rem" }}
                >
                  All 4 weeks
                </h6>
              </div>
              <Row gutter={[8, 8]} className="my-3">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <DatePicker
                    allowClear={false}
                    disabledDate={disabledDate}
                    defaultValue={moment(initalMonth, monthFormat)}
                    format={monthFormat}
                    picker="month"
                    onChange={handleMonthChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="graph-div">
            {/* <Column {...config} /> */}{" "}
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {!chartData.length && !loading ? (
              <Empty
                //   image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 100,
                }}
              />
            ) : (
              <Column {...config} />
              //   <p>none</p>
            )}
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default OpportunityWeeklyCount;
