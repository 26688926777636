import React, { useCallback, useEffect, useState } from "react";
import {
  Layout,
  Dropdown,
  Avatar,
  Badge,
  notification,
  Menu,
  Col,
  Row,
  Divider,
} from "antd";
import { Link, useNavigate } from "react-router-dom";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SiderDrawerPhone from "../dashboard/SiderDrawerPhone";
import moment from "moment";

import {
  BellFilled,
  HomeFilled,
  HomeOutlined,
} from "@ant-design/icons/lib/icons";

import axios from "axios";

const { Header } = Layout;

export default function HeaderAdmin(props) {
  const navigate = useNavigate();

  const logout = () => {
    console.log("called logout");
    localStorage.clear();
    navigate("/");
  };

  const getFullName = (email) => {
    try {
      email = email.split("_");

      email = email[1].split("@");

      email = email[0].split(".");

      return `${capitalize(email[0])} ${capitalize(email[1])}`;
    } catch (error) {
      return email;
    }
  };

  const capitalize = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  // const items = [
  //   {
  //     label: (
  //       <Link
  //         to={
  //           localStorage.getItem("access_level") === "ADMIN" ||
  //           localStorage.getItem("access_level") === "ORGANIZATION"
  //             ? "/organization"
  //             : "/projects"
  //         }
  //         className="ms-4 fs-4"
  //       >
  //         <HomeOutlined />
  //         <span className="ms-4 menu-item-name" style={{ paddingLeft: "5px" }}>
  //           Home
  //         </span>
  //       </Link>
  //     ),
  //     key: "0",
  //   },
  //   {
  //     label: (
  //       <span className="ms-4 fs-4" onClick={props.signOut}>
  //         <LogoutOutlined />
  //         <span
  //           className="ms-4 menu-item-name"
  //           onClick={logout}
  //           style={{ paddingLeft: "5px" }}
  //         >
  //           Sign Out
  //         </span>
  //       </span>
  //     ),
  //     key: "1",
  //   },
  // ];

  const items = [
    {
      label: (
        <Link to="/" className="ms-4 fs-4">
          <HomeFilled />

          <span className="ms-4 menu-item-name">Home</span>
        </Link>
      ),

      key: "0",
    },

    {
      label: (
        <span className="ms-4 fs-4" onClick={props.signOut}>
          <LogoutOutlined />

          <span className="ms-4 menu-item-name">Sign Out</span>
        </span>
      ),

      key: "1",
    },
  ];
  return (
    <Header className="site-layout-background bg-white" style={{ padding: 0 }}>
      {React.createElement(
        props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,

        {
          className: "trigger",

          onClick: props.toggle,
        }
      )}

      <SiderDrawerPhone user={props.user} />

      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <div
          className="avatar-user-details"
          onClick={(e) => e.preventDefault()}
        >
          <div className="avatar-box">
            <span className="avatar-name" style={{ color: "##c3c7cc" }}>
              <strong style={{ fontWeight: "200" }}>
                {getFullName(props.user.username)}
              </strong>
            </span>

            <div className="avatar-img">
              <Avatar
                // style={{ color: "#c3c7cc"}}

                size={40}
                icon={<UserOutlined />}
              />
            </div>
          </div>
        </div>
      </Dropdown>

      <span className="icons-bar float-end">
        <Divider type="vertical" style={{ color: "#000", height: "5rem" }} />

        <Badge key="green" color="rgb(42, 174, 9)" dot={true} size="large">
          <BellFilled className="bell" />
        </Badge>

        {/** <Badge key="blue" color="rgb(25, 118, 210)" count={1} showZero size="default">

        <BellOutlined className="bell" />

      </Badge> */}

        <Divider type="vertical" style={{ color: "#000", height: "5rem" }} />
      </span>
    </Header>
  );
}
