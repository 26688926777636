// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// import firebase from "firebase";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

 

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCW8NJLPaVbsxY8wEoTFRVxQs7xYtfI9o8",
  authDomain: "cmst-push-messaging.firebaseapp.com",
  databaseURL: "https://cmst-push-messaging-default-rtdb.firebaseio.com",
  projectId: "cmst-push-messaging",
  storageBucket: "cmst-push-messaging.appspot.com",
  messagingSenderId: "634955901403",
  appId: "1:634955901403:web:b4843288c211c10e4f9ffe",
  measurementId: "G-09DNMMLZ5X",

};

 

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const messaging = getMessaging(app);
export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BMXk_BK5DB1g4-vUbsvC48tCFpqKLauZnXagcpv-jbRX2o-8rRH8teOxuIlTfVvgguUd-hhfxUkgK7Yc3WtyoE4",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
  
export default database;