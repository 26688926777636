import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  message,
  Steps,
  theme,
  Row,
  Col,
  Badge,
  Spin,
  Form,
  Space,
  Divider,
  Card,
  Descriptions,
} from "antd";
import BreadCrumbs from "../BreadCrumbs";
import axios from "axios";
import FetchQuestion from "./FetchQuestion";
import Typography from "antd/lib/typography/Typography";
import { IeCircleFilled } from "@ant-design/icons";
const Questionnaire = (props) => {
  // const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [categoryQuestions, setCategoryQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [moveToNext, setMoveToNext] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [disableSave, setDisableSave] = useState(false);
  const [previousAnswers, setPreviousAnswers] = useState([]);
  const [pastQnaArray, setPastQnaArray] = useState([]);
  const [pageDisabled, setPageDisabled] = useState(false);
  const [viewOnlyMode, setViewOnlyMode] = useState(false);
  const [formLoading, setFormLoading] = useState(true);

  const page_ref = useRef(null);

  const { Step } = Steps;
  const pageMetaData = {
    2: { start: 0, end: 16 },
    3: { start: 17, end: 23 },
    4: { start: 24, end: 28 },
    5: { start: 29, end: 31 },
    6: { start: 32, end: 40 },
    7: { start: 41, end: 44 },
    8: { start: 45, end: 46 },
    9: { start: 47, end: 55 },
    lastPage: 9,
  };
  var flag = false;

  const record = props.location.state.record;
  useEffect(() => {
    // console.log("props inside qna", props);
    getAllQuestions();
    getPreviousAnswers();
  }, []);

  const getPreviousAnswers = async (questions) => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/question/getqna";
    const params = {
      opportunity: record.opportunityId,
    };
    try {
      let res = await axios.get(url, { params });

      setPreviousAnswers(res.data);
    } catch (err) {
      console.log("error from previous answers", err);
    }
  };

  const getAllQuestions = async () => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/question/getallquestion";
    try {
      let res = await axios.get(url);

      setAllQuestions(res.data);
      setFormLoading(false);
    } catch (error) {
      console.log("error from get all questions", error);
    }
  };
  const getPreviousAnswersByCategory = (category_name) => {
    let category_questions = [];
    allQuestions.map((ques) => {
      if (ques.categoryCode === category_name) category_questions.push(ques);
    });

    let final_answers = [];
    previousAnswers.map((que) => {
      category_questions.map((item) => {
        if (que.questionId === item.id) final_answers.push(que);
      });
    });

    if (final_answers.length > 0) return final_answers;
    else return [];
  };
  const getQuestionsByCategory = (category_name) => {
    let result = [];

    allQuestions.map((question) => {
      if (question.categoryCode === category_name) result.push(question);
    });

    return result;
  };
  const onFinish = async (values) => {
    console.log("values on finish", values);
    console.log("stepsss's current", steps[current + 1]);
    if (steps[current + 1].content.props.pastQnaArray.length > 0) {
      console.log("steps enable view only");
      setViewOnlyMode(true);
    } else {
      setViewOnlyMode(false);
    }

    next();
  };
  const saveDraft = async (submit) => {
    setSaveLoading(true);
    let qna_array = [];
    let answers = 0;
    let current_page = page_ref.current;
    let category_questions = getQuestionsByCategory(steps[current].code);
    // console.log("category questions==", category_questions);
    category_questions.map((question) => {
      if (current_page.getFieldValue(question.id)) {
        qna_array.push({
          questionId: question.question,
          answer: current_page.getFieldValue(question.id),
        });
        answers++;
      }
    });
    let keyValue = current_page.getFieldsValue();
    console.log("key value ", Object.keys(keyValue).length);
    let totalQuestionsInForm = Object.keys(keyValue).length;
    if (answers === 0) {
      message.error("Please fill answers before proceeding");
    } else if (answers < totalQuestionsInForm) {
      message.error(
        "Please fill all the answers on this page in order to save them."
      );
    } else {
      console.log("all fields values", current_page.getFieldsValue());
      console.log("qna array to save", qna_array, answers);
      let url =
        "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/question/qna";
      let params = {
        opportunityId: record.opportunityId,
        qna: qna_array,
      };
      try {
        let res = await axios.post(url, params);
        console.log("response from save qna", res.data.body);

        if (submit === "submit") {
          message.success("Submitted Successfully");
        } else {
          message.success("Answers saved");
        }
        setDisableSave(true);
        setDisableNext(false);
      } catch (error) {
        console.log("error from save qna", error);
      }
    }

    setMoveToNext(false);
    setSaveLoading(false);
  };
  const doAfterDisablingPage = (length) => {
    console.log("length===", length);
    if (length > 0) {
      setDisableSave(true);
      setDisableNext(false);
      setPageDisabled(true);
    }
  };
  const steps = [
    {
      title: "General Q&A",
      content: (
        <FetchQuestion
          //toRenderObj={toRenderObj}
          pastQnaArray={getPreviousAnswersByCategory("qa")}
          reference={page_ref}
          questions={getQuestionsByCategory("qa")}
          doAfterDisablingPage={doAfterDisablingPage}
        />
      ),
      code: "qa",
    },
    {
      title: "Security",
      content: (
        <FetchQuestion
          // toRenderObj={toRenderObj}
          pastQnaArray={getPreviousAnswersByCategory("security")}
          reference={page_ref}
          questions={getQuestionsByCategory("security")}
          doAfterDisablingPage={doAfterDisablingPage}
        />
      ),
      code: "security",
    },
    {
      title: "Integration",
      content: (
        <FetchQuestion
          // toRenderObj={toRenderObj}
          pastQnaArray={getPreviousAnswersByCategory("integration")}
          reference={page_ref}
          questions={getQuestionsByCategory("integration")}
          doAfterDisablingPage={doAfterDisablingPage}
        />
      ),
      code: "integration",
    },
    {
      title: "UI-UX",
      content: (
        <FetchQuestion
          // toRenderObj={toRenderObj}
          pastQnaArray={getPreviousAnswersByCategory("ui/ux")}
          reference={page_ref}
          questions={getQuestionsByCategory("ui/ux")}
          doAfterDisablingPage={doAfterDisablingPage}
        />
      ),
      code: "ui/ux",
    },
    {
      title: "Data",
      content: (
        <FetchQuestion
          // toRenderObj={toRenderObj}
          pastQnaArray={getPreviousAnswersByCategory("data")}
          reference={page_ref}
          questions={getQuestionsByCategory("data")}
          doAfterDisablingPage={doAfterDisablingPage}
        />
      ),
      code: "data",
    },
    {
      title: "DevOps",
      content: (
        <FetchQuestion
          // toRenderObj={toRenderObj}
          pastQnaArray={getPreviousAnswersByCategory("devops")}
          reference={page_ref}
          questions={getQuestionsByCategory("devops")}
          doAfterDisablingPage={doAfterDisablingPage}
        />
      ),
      code: "devops",
    },
    {
      title: "Mobile-App",
      content: (
        <FetchQuestion
          // toRenderObj={toRenderObj}
          pastQnaArray={getPreviousAnswersByCategory("mobile_app")}
          reference={page_ref}
          questions={getQuestionsByCategory("mobile_app")}
          doAfterDisablingPage={doAfterDisablingPage}
        />
      ),
      code: "mobile_app",
    },
    {
      title: "App Devs",
      content: (
        <FetchQuestion
          // toRenderObj={toRenderObj}
          pastQnaArray={getPreviousAnswersByCategory("app_dev")}
          reference={page_ref}
          questions={getQuestionsByCategory("app_dev")}
          doAfterDisablingPage={doAfterDisablingPage}
        />
      ),
      code: "app_dev",
    },
  ];
  useEffect(() => {
    if (steps[current].content.props.pastQnaArray.length > 0) {
      console.log("steps enable view only");
      setViewOnlyMode(true);
    } else {
      setViewOnlyMode(false);
    }
  }, [steps[0].content.props.pastQnaArray]);
  const next = () => {
    setDisableNext(true);
    setDisableSave(false);
    setCurrent(current + 1);
  };
  const prev = () => {
    setDisableSave(true);
    setDisableNext(false);
    setCurrent(current - 1);
    form.setFieldsDisabled(true);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      <div className="card-body">
        <h1 className="page-title">Questionnaire</h1>
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <div className="d-flex  justify-content-end mx-0">
          {/* <Card
            style={{
              width: 200,
              height: 30,
            }}
          >
            <Typography>{record.name}</Typography>
          </Card> */}
        </div>
        <div className="justify-content-end mx-5">
          {/* <Typography>{record["name"]}</Typography> */}
          <div className="card  mx-20 my-3 pt-20">
            <Form
              form={form}
              className="qna_form"
              name="dynamic_form_nest_item"
              style={{ overflow: "scroll" }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                remember: true,
              }}
              ref={page_ref}
            >
              <div>
                {formLoading && (
                  <div
                    className="mt-6 text-center"
                    style={{ marginTop: "10%" }}
                  >
                    <Spin size="medium"></Spin>
                  </div>
                )}
                {/* {loading && ( */}

                {/* <div className="d-flex justify-content-center">

  <Spin size="large" tip="Loading..."></Spin>

</div> */}

                {/* )} */}

                {/* {!loading && ( */}
                {/* {formLoading && (
                  <div className="d-flex justify-content-center">
                    <Spin size="large" tip="Loading..."></Spin>
                  </div>
                )} */}

                {!formLoading && (
                  <div>
                    <Steps size="small" current={current}>
                      {steps.map((item, index) => (
                        <Step key={item.title} title={item.title} />
                      ))}
                    </Steps>

                    <hr />

                    {viewOnlyMode ? (
                      <Badge.Ribbon
                        text="View Mode"
                        color="#5a6169"
                        style={{ marginRight: "10px" }}
                      ></Badge.Ribbon>
                    ) : null}

                    <Form.Item>
                      <div className="steps-content">
                        {steps[current].content}
                      </div>

                      <div className="steps-action"></div>
                    </Form.Item>
                    <Divider />
                    <Form.Item className="d-flex justify-content-center">
                      {/* {oppObj.disable ? ( */}

                      {/* <Button
                      disabled={false}
                      className="view-next-btn"
                      type="primary"

                      // onClick={viewMode}
                    >
                      <b> View Next</b>
                    </Button> */}

                      {/* ) : ( */}

                      <>
                        {current > 0 && (
                          <Button
                            className="back-btn mx-5"
                            type="primary"
                            onClick={prev}
                          >
                            Previous
                          </Button>
                        )}

                        {current === steps.length - 1 && (
                          <Button
                            className="ok-modal"
                            type="primary"
                            // htmlType="submit"
                            disabled={disableSave}
                            onClick={() => {
                              saveDraft("submit");
                            }}
                            name="submit"
                          >
                            Submit
                          </Button>
                        )}

                        {current !== steps.length - 1 && (
                          <Button
                            className="ok-modal"
                            type="primary"
                            loading={saveLoading}
                            onClick={saveDraft}
                            disabled={disableSave}
                          >
                            Save Answers
                          </Button>
                        )}

                        {current < steps.length - 1 && (
                          <Button
                            className="back-btn mx-5"
                            type="primary"
                            htmlType="submit"
                            disabled={disableNext}
                            // onClick={() => next()}
                          >
                            Next
                          </Button>
                        )}
                      </>

                      {/* )} */}
                    </Form.Item>
                  </div>
                )}
                {/* )} */}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Questionnaire;
