import React, { Component } from "react";
import { Row, Col, Breadcrumb } from "antd";
import OpportunityCountCards from "./dashboard_cards/OpportunityCountCards";
import OpportunityGraph from "./dashboard_cards/OpportunityGraph";
import OpportunityWeeklyCount from "./dashboard_cards/OpportunityWeeklyCount";

const Dashboard = () => {
  return (
    <div className="container">
      <div className="container-fluid statistics-card ">
        <OpportunityGraph />
        <span className="div-dash">
          <OpportunityCountCards />
        </span>

        <Row gutter={[16, 16]} style={{ marginTop: "2%" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="card mx-0">
              <div className="card-body">
                <OpportunityWeeklyCount />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
