import React, { Component } from "react";
import { Menu, Avatar, Drawer } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  AmazonOutlined,
  WindowsOutlined,
  GoogleOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { brand, routes } from "../../util/constants";

const { SubMenu } = Menu;
let SubMenukey = "";
export default class SiderDrawerPhone extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, placement: "left" };
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <span>
        <MenuUnfoldOutlined
          onClick={this.showDrawer}
          className="trigger-phone"
        />
        <Drawer
          placement={this.state.placement}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          key={this.state.placement}
        >
          {/**<div className="sider-menu">
            <div className="logo-admin">
              <img
                src={logo}
                alt="Logo"
                width={130}
                height={130}
                style={{ margin: "60px 0 0px 85px" }}
              />
            </div>
          </div>
          <div className="profile-section-sider mb-3 avatar">
            <Avatar
              icon={<UserOutlined />}
              style={{
                color: "#0060aa",
                backgroundColor: "#e8f0fe",
                letterSpacing: "1px",
              }}
              size={60}
              className="mt-5 "
            ></Avatar>
            <p className="profile-name-sider" title="  Daga Impex">
              {this.props.user.username}
            </p>
          </div> */}
          <div className="brand-name">
            <div className="brand-logo">
              <svg
                width="24px"
                height="24px"
                // className={className}
                viewBox="0 0 240 239"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="logo"
                    transform="translate(120.500000, 98.000000) rotate(-270.000000) translate(-120.500000, -98.000000) translate(-21.000000, -35.000000)"
                  >
                    <g
                      id="Group"
                      transform="translate(141.500000, 133.000000) rotate(90.000000) translate(-141.500000, -133.000000) translate(9.000000, -8.000000)"
                    >
                      <g
                        id="Path-4-Copy"
                        transform="translate(132.242532, 149.243361) rotate(-135.000000) translate(-132.242532, -149.243361) translate(38.742532, 55.743361)"
                      >
                        <g id="path-1-link" fill="#000000">
                          <polygon
                            id="path-1"
                            points="136.269985 0.8695976 186.615824 50.367072 186.615824 186.98041 50.7100649 186.98041 0.4346189 136.704964"
                          ></polygon>
                        </g>
                        <g id="path-1-link" fill="rgb(71, 145, 219)">
                          <polygon
                            id="path-1"
                            points="136.269985 0.8695976 186.615824 50.367072 186.615824 186.98041 50.7100649 186.98041 0.4346189 136.704964"
                          ></polygon>
                        </g>
                      </g>
                      <polygon
                        id="Path-3"
                        fill="rgb(71, 145, 219)"
                        points="13 238.415212 13 0 249.898437 238.415212"
                      ></polygon>
                      <polygon
                        id="Path-4"
                        fill="#1976d2"
                        points="252.486992 0 252.486992 238.799226 13 238.799226"
                      ></polygon>
                    </g>
                  </g>
                </g>
              </svg>
              <span className="app-name" mode="full">
                {brand.NAME}
              </span>
            </div>
          </div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["/dashboard"]}
          >
            <Menu.Item key="/dashboard" icon={<HomeOutlined />}>
              <NavLink className="sider-links " to="/dashboard">
                Dashboard
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={routes.AWS_BILLINGS}
              icon={<AmazonOutlined />}
              title="Amazon Web Services"
            >
              <NavLink className="sider-links " to={routes.AWS_BILLINGS}>
                Amazon Web Services
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={routes.AZURE_BILLINGS}
              icon={<WindowsOutlined />}
              title="Microsoft Azure"
            >
              <NavLink className="sider-links " to={routes.AZURE_BILLINGS}>
                Microsoft Azure
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={routes.GCP_BILLINGS}
              icon={<GoogleOutlined />}
              title="Google Cloud Platform"
            >
              <NavLink className="sider-links " to={routes.GCP_BILLINGS}>
                Google Cloud Platform
              </NavLink>
            </Menu.Item>
          </Menu>
        </Drawer>
      </span>
    );
  }
}
