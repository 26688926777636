import { combineReducers } from "redux";
// import chartReducer from "./chartReducer";
// import errorReducer from "./errorReducer";
// import userReducer from "./userReducer";
// import jobCardReducer from "./jobCardReducer";
const rootReducer = combineReducers({
  // errors: errorReducer,
  // users: userReducer,
  // chartData: chartReducer,
  // jobcard: jobCardReducer,
});

export default rootReducer;
