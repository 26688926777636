import {
  ArrowRightOutlined,
  AuditOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Skeleton } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

const OpportunityCountCards = () => {
  const [draftCount, setDrafCount] = useState(0);
  const [inProcessCount, setInProcessCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);

  useEffect(() => {
    getAllOppCount();
  }, []);
  const getAllOppCount = () => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/dashboard/opportunitystatus";
    axios
      .get(url)
      .then((res) => {
        console.log("response frm opp count cards", res.data.body[0]);
        const result = res.data.body[0];
        setDrafCount(result["Draft"]);
        setInProcessCount(result["InProgress"]);
        setClientCount(result["Approved"]);
      })
      .catch((err) => {
        console.log("error from opp count cards", err);
      });
  };
  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className="count-cards mt-3">
          <Row gutter={[24, 8]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div>
                <div className="card-body2">
                  <div className="details">
                    <AuditOutlined />
                    <span className="count ms-3">
                      <span className="name-cloud">Opportunities In Draft</span>
                      <br></br>
                      <p
                        style={{
                          color: "#3d5170",
                          marginTop: "3px",
                          fontWeight: "400",
                        }}
                      >
                        {draftCount}
                      </p>
                    </span>
                  </div>
                  <div className="buttons">
                    <Button size="large" type="text">
                      <ArrowRightOutlined style={{ color: "#3d5170" }} />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div>
                <div className="card-body3">
                  <div className="details">
                    <FileSyncOutlined />
                    <span className="count ms-3">
                      <span className="name-cloud">
                        Opportunities In Process
                      </span>
                      <br></br>
                      <p
                        style={{
                          color: "#3d5170",
                          marginTop: "3px",
                          fontWeight: "400",
                        }}
                      >
                        {inProcessCount}
                      </p>
                    </span>
                  </div>
                  <div className="buttons">
                    <Button size="large" type="text">
                      <ArrowRightOutlined style={{ color: "#3d5170" }} />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div>
                <div className="card-body4">
                  <div className="details">
                    <FileDoneOutlined />
                    <span className="count ms-3">
                      <span className="name-cloud">
                        Opportunities Sent To Client
                      </span>
                      <br></br>
                      <p
                        style={{
                          color: "#3d5170",
                          marginTop: "3px",
                          fontWeight: "400",
                        }}
                      >
                        {clientCount}
                      </p>
                    </span>
                  </div>
                  <div className="buttons">
                    <Button size="large" type="text">
                      <ArrowRightOutlined style={{ color: "#3d5170" }} />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default OpportunityCountCards;
