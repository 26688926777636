import React, { useEffect, useState } from "react";
import axios from "axios";
import BreadCrumbs from "../BreadCrumbs";
import {
  Row,
  Col,
  Table,
  Popconfirm,
  Button,
  Space,
  Select,
  Tooltip,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  CheckOutlined,
  DeleteTwoTone,
  EditOutlined,
  EyeTwoTone,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { routes } from "../../util/constants";

const TaskEstimation = (props) => {
  const [allTaskEstimations, setAllTaskEstimations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [sentForApproval, setSentForApproval] = useState(false);
  const navigate = useNavigate();
  const record = props.location.state.record;
  useEffect(() => {
    console.log("record inside task estimation", props.location.state.record);
    if (record.status === "APPROVED_ESTIMATION") setSentForApproval(true);

    getTaskEstimations();
  }, []);

  const estimationId = props.location.state.record.estimationId;
  const disableButtons = props.location.state.record.disabled;

  const submittedBy = props.location.state.record.estimatedByEmail;

  const getTaskEstimations = async () => {
    setLoading(true);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/taskestimation/gettaskestimationbyid";
    const params = {
      estimationId: estimationId,
    };
    console.log("record", props.location.state.record);
    try {
      let result = await axios.get(url, { params });
      console.log("result from get all  task estimations", result.data);
      setAllTaskEstimations(result.data);

      console.log("before adding flag");
      addFlagInItem(result.data);
      console.log("after adding flag");
      setLoading(false);
    } catch (error) {
      console.log("error from get all task estimations", error);
    }
  };

  const callOnRowEdit = (record) => {
    let temp_rows = [...rows];
    console.log("callOnRowEdit====>", record);
    const currentIndex = temp_rows.indexOf(
      temp_rows.find((e) => e.id == record.id)
    );
    const enabled = temp_rows[currentIndex]["isEnable"];
    temp_rows[currentIndex]["isEnable"] = !enabled;
    setRows(temp_rows);
    //setEditingEnabled(true);
  };

  const addFlagInItem = async (taskEstimationList) => {
    try {
      await taskEstimationList.forEach((value, index, array) => {
        value["isEnable"] = false;
        value["editingEnabled"] = false;
      });
      setRows(taskEstimationList);
      console.log("added flag");
      console.log("task etimation list after flag", taskEstimationList);
    } catch (err) {
      console.log("error from flag ", err);
    }
  };

  const handleChange = (record) => (e) => {
    const { name, value } = e.target;
    const row = [...rows];
    console.log("handleChangeName====>", name);
    console.log("handleChangeName====>", value);
    console.log("handleChangeEvent====>", e);
    const currentIndex = row.indexOf(row.find((e) => e.id == record.id));

    row[currentIndex][name] = value;

    console.log("handleChangeRow====>", row);
    setRows(row);
  };
  const callApproveEstimation = async () => {
    setApproveLoading(true);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/estimation/approveestimation";
    const params = {
      id: estimationId,
    };
    try {
      let res = await axios.post(url, params);
      console.log("response from approve estimation", res);
      setApproveLoading(false);
      setSentForApproval(true);
      message.success("Estimation Approved");
    } catch (error) {
      console.log("error from approve estimation");
    }
  };

  const handleAddRow = () => {
    const item = {
      date: "",
      taskTitle: "",
      taskDescription: "",
      hours: "",
      complexity: "",
      comments: "",
      isEnable: true,
    };
    setRows([...rows, item]);
  };
  const callDeleteTaskEstimation = async (record) => {
    console.log("record te", record.id);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/taskestimation/delete";
    const params = {
      id: record.id,
    };
    try {
      let res = await axios.delete(url, { params });
      console.log("response after delete te", res);
      let new_rows = rows.filter((element) => element.id !== record.id);
      setRows(new_rows);
    } catch (error) {
      console.log("error after delete te", error);
    }
  };
  const createOrUpdateTaskEstimation = async (record) => {
    // record[editingEnabled] = true;

    console.log(
      "record inside save changes",
      record,
      localStorage.getItem("userEmail")
    );
    const params = {
      id: record.id,
      taskTitle: record.taskTitle,
      taskDescription: record.taskDescription,
      hours: record.hours,
      comments: record.comments,
      complexity: record.complexity,
      userEmail: localStorage.getItem("userEmail"),
      estimationId: estimationId,
      submittedBy: submittedBy,
    };

    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/taskestimation";
    try {
      let res = await axios.post(url, params);
      console.log("response after updating task estimation", res);
      // setEditingEnabled(false);
      let temp_rows = [...rows];
      console.log("callOnRowEdit====>", record);
      const currentIndex = temp_rows.indexOf(
        temp_rows.find((e) => e.id == record.id)
      );
      const enabled = temp_rows[currentIndex]["isEnable"];
      temp_rows[currentIndex]["isEnable"] = !enabled;
      setRows(temp_rows);
      getTaskEstimations();
    } catch (error) {
      console.log("error inside update task es", error);
    }
  };
  const complexity = [
    {
      value: "low",
      label: "Low",
    },
    {
      value: "medium",
      label: "Medium",
    },
    {
      value: "high",
      label: "High",
    },
  ];

  const handleComplexityChange = (value, idx) => {
    console.log("handleComplexityChange=====>", value);

    const row = [...rows];
    const currentIndex = row.indexOf(row.find((e) => e._id == idx._id));

    row[currentIndex]["complexity"] = value;
    console.log("handleChangeRow====>", row);
    setRows(row);
  };

  //   const navigateToTaskEstimations = (record) => {
  //     navigate(routes.TASK_ESTIMATION, {
  //       state: {
  //         record,
  //       },
  //     });
  //   };
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      width: "45%",
      render: (record) => (
        <>{moment(record).format("MMMM Do YYYY, h:mm:ss a")}</>
      ),
    },
    {
      title: "Title",
      dataIndex: "taskTitle",
      width: "15%",
      render: (text, record, index) => (
        <input
          name="taskTitle"
          placeholder="Title"
          value={record.taskTitle}
          onChange={handleChange(record)}
          className="estimate-input"
          disabled={record.isEnable ? "" : "disabled"}
        />
      ),
    },
    {
      title: "Hours",
      dataIndex: "hours",
      width: "10%",
      render: (text, record, index) => (
        <input
          type="number"
          min="0"
          name="hours"
          placeholder="Hours"
          value={record.hours}
          onChange={handleChange(record)}
          className="estimate-input"
          style={{ width: "100%" }}
          disabled={record.isEnable ? "" : "disabled"}
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "taskDescription",
      render: (text, record, index) => (
        <input
          name="taskDescription"
          placeholder="Description"
          value={record.taskDescription}
          onChange={handleChange(record)}
          className="estimate-input"
          disabled={record.isEnable ? "" : "disabled"}
        />
      ),
    },
    {
      title: "Complexity",

      //   width: "100%",
      render: (item, idx) => (
        <>
          <Select
            defaultValue={item.complexity ? item.complexity : "Complexity"}
            style={{
              width: 120,
            }}
            onChange={(value) => {
              handleComplexityChange(value, idx);
            }}
            options={complexity}
            disabled={!idx.isEnable}
          />
        </>
      ),
    },
    {
      title: "Comments",
      //   width: "10%",
      render: (item, record) => (
        <>
          <input
            name="comments"
            placeholder="Comments"
            value={record.comments}
            onChange={handleChange(record)}
            className="estimate-input"
            disabled={record.isEnable ? "" : "disabled"}
          />
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "rff",
      width: "10%",

      render: (text, record) => {
        return (
          <>
            <div>
              {record.isEnable === true && (
                <Tooltip title="Save Changes">
                  <CheckOutlined
                    className="icon-class"
                    onClick={() => {
                      createOrUpdateTaskEstimation(record);
                    }}
                  />
                </Tooltip>
              )}
              {record.isEnable === false && (
                <Tooltip title="Edit Row">
                  <EditOutlined
                    className="icon-class"
                    onClick={() => {
                      // console.log("Edit====>", idx);
                      callOnRowEdit(record);
                    }}
                  />
                </Tooltip>
              )}
              &nbsp;&nbsp;&nbsp;
              <Popconfirm
                placement="left"
                title="Are you sure, you want to delete this estimation"
                onConfirm={() => {
                  callDeleteTaskEstimation(record);
                }}
                icon={
                  <QuestionCircleOutlined className="QuestionCircle-class " />
                }
              >
                <Button className="btn-for-icon" disabled={sentForApproval}>
                  <DeleteTwoTone
                    twoToneColor="#d91d0f"
                    style={{ fontSize: "18px" }}
                  />
                </Button>
              </Popconfirm>
            </div>
            {/* // )} */}
          </>
        );
      },
    },
  ];
  const filteredColumns = columns.filter((col) => col.title !== "Action");

  return (
    <>
      <div className="card-body">
        <h1 className="page-title">Task Estimations</h1>
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>

        <div
          className="d-flex justify-content-end mx-0"
          style={{
            visibility:
              record.viewOnly ||
              record.estimatedByEmail !== localStorage.getItem("userEmail") ||
              record.status === "APPROVED_ESTIMATION"
                ? "hidden"
                : "",
          }}
        >
          <Button
            onClick={handleAddRow}
            // className="mx-4"
            disabled={sentForApproval}
          >
            <Space>Add Row</Space>
          </Button>
          {/* <Button
            type="primary"
            className=" "
            // onClick={createOpportunityNavigator}
          >
            <Space>Submit Estimation</Space>
          </Button> */}
        </div>

        <div className="justify-content-end mx-5">
          <div className="card  mx-20 my-3 pt-20 ">
            {/* {pageLoading && (
                    <div className="d-flex justify-content-center">
                      <Spin size="medium" tip="Loading..."></Spin>
                    </div>
                  )} */}
            {/* {!pageLoading && ( */}
            <Table
              loading={loading}
              columns={
                record.viewOnly || sentForApproval ? filteredColumns : columns
              }
              dataSource={rows}
              className="table-striped-rows"
            />
            {/* )} */}
          </div>
        </div>
        {localStorage.getItem("roleName") === "Solution Architect" && (
          <div
            className="text-center mx-0"
            style={{
              visibility:
                record.viewOnly ||
                record.status === "APPROVED_ESTIMATION" ||
                rows.length === 0
                  ? "hidden"
                  : "",
            }}
          >
            <Popconfirm
              placement="bottom"
              title="Are you sure, you want to approve this estimation"
              onConfirm={() => {
                callApproveEstimation();
              }}
              icon={
                <QuestionCircleOutlined className="QuestionCircle-class " />
              }
            >
              <Button
                type="primary"
                loading={approveLoading}
                disabled={sentForApproval}
              >
                Approve Estimation
              </Button>
            </Popconfirm>
          </div>
        )}
      </div>
    </>
  );
};

export default TaskEstimation;
