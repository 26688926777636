import React, { Component } from "react";
import { Divider, Menu } from "antd";
import { NavLink } from "react-router-dom";
import { AlertOutlined, LayoutFilled, LayoutOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { brand } from "../../util/constants";

const { SubMenu } = Menu;
let SubMenukey = "";

const mapStateToProps = (state) => ({
  location: PropTypes.object.isRequired,
});
const SiderMenu = (props) => {
  return (
    <div
      className={props.collapsed ? "sider-menu" : "sider-menu menu-position"}
    >
      <div className="brand-name bg-white">
        <div className="brand-logo">
          {brand.LOGO}
          <span class="app-name" mode="full">
            {brand.NAME}
          </span>
        </div>
      </div>

      <Menu them="light" mode="inline" defaultSelectedKeys={["/dashboard"]}>
        <Menu.Item
          key="/dashboard"
          icon={<LayoutOutlined className="icon-class" />}
        >
          <NavLink className="sider-links" to="/dashboard">
            Dashboard
          </NavLink>
        </Menu.Item>
        <p class="menu-subhead" mode="full">
          QuickLinks
        </p>
        <Menu.Item key="/opportunity" icon={<AlertOutlined />}>
          <NavLink className="sider-links " to="/opportunity">
            Opportunities
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default SiderMenu;
