import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Tooltip,
  Button,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Option } from "antd/lib/mentions";
import axios from "axios";

const AddContributorForm = ({ record, getLatestList }) => {
  const [open, setOpen] = useState(false);
  const [roleMappedUsers, setRoleMappedUsers] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [userEmails, setUserEmails] = useState([]);
  const [roleUsers, setRoleUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [fields, setFields] = useState({
    role: "",
    opp_name: record.name,
    email: "",
    source_requestor: localStorage.getItem("userEmail"),
  });
  // const record = props.record;

  useEffect(() => {
    console.log("record inside add contri", record);
    getAllRoles();
  }, []);
  const onSubmit = async () => {
    setSaveLoading(true);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/opportunityusermapping";
    const params = {
      email: email,
      opportunity: record.name,
      currentStatus: record.statusId,
      role: selectedRole,
    };
    console.log("params for save contri", params);
    try {
      let res = await axios.post(url, params);
      console.log("response from save contri", res);
      let url2 =
        "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/contributor";
      let params2 = {
        opportunityId: record.opportunityId,
      };
      let res2 = await axios.get(url2, { params: params2 });
      message.success("Contributor added successfully");
      getLatestList(res2.data);
    } catch (error) {
      console.log("error from save contri", error);
    }
    setSaveLoading(false);
  };

  const getAllRoles = async () => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/role";
    try {
      let res = await axios.get(url);
      console.log("response from get all roles", res);
      let roles = [];
      res.data.map((item) => {
        roles.push(item.roleName);
      });
      console.log("rolesss", roles);
      setAllRoles(roles);
      mapUsersWithRoles(roles);
    } catch (error) {
      console.log("error from get all roles", error);
    }
  };
  const getUsersByRoleName = (roleName) => {
    setSelectedRole(roleName);
    let userObj = roleMappedUsers[roleName];
    console.log("role Name", roleName, userObj);

    setUserEmails(userObj);
  };
  const getUserNameForField = (obj) => {
    console.log("objjjjjjj", obj);
    setEmail(obj);
    console.log("emailsssssssssss", userEmails);
    userEmails.map((item) => {
      if (item.userEmail === obj) {
        console.log("usernameeeeeee", item.userName);
        setUserName(item.userName);
      }
    });
  };
  const mapUsersWithRoles = async (roles) => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/user/getall";
    try {
      let res = await axios.get(url);
      console.log("response from get all users", res.data.body);
      console.log("roles inside map ", roles);
      let usersData = res.data.body;
      let roleMappedObj = {};
      roles.map((role) => {
        let usersArray = [];
        usersData.map((user) => {
          if (user.roleName === role) {
            let userObj = {
              userName: user.name,
              userEmail: user.email,
            };
            usersArray.push(userObj);
          }
        });
        roleMappedObj[role] = usersArray;
      });
      console.log("role mapped users==", roleMappedObj);
      setRoleMappedUsers(roleMappedObj);
    } catch (error) {
      console.log("error from map users", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFields({ ...fields, [name]: value });
  };

  return (
    <Form
      name="Add Contributor"
      className=""
      //   form={form}
      //   onKeyDown={onKeyDownSubmit}
      onFinish={onSubmit}
      initialValues={{
        remember: true,
      }}
    >
      <Row gutter={[8, 8]}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form.Item name="Role" noStyle>
            <Tooltip title="Select Contirbutor's Role">
              <Select
                placeholder="Select Contirbutor's Role"
                style={{
                  width: "100%",
                }}
                onChange={(select) => {
                  getUsersByRoleName(select);
                }}
                // value={fields.role}
              >
                {allRoles?.map((item) => {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </Tooltip>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form.Item name="Email" noStyle>
            <Tooltip title="Select Contributor's Email">
              <Select
                placeholder="Select Contributor's Email"
                style={{
                  width: "100%",
                }}
                disabled={!selectedRole}
                onChange={(select) => {
                  getUserNameForField(select);
                }}
                // defaultValue={fields.status}
              >
                {userEmails?.map((item) => {
                  return (
                    <Option value={item.userEmail}>{item.userEmail}</Option>
                  );
                })}
              </Select>
            </Tooltip>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 8]} style={{ marginTop: "30px" }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Tooltip title="Opportunity Name">
            <Form.Item name="opp_name">
              <Input
                autoFocus
                name="opportunity_name"
                defaultValue={fields.opp_name}
                // value={userName}
                // onChange={handleChange}
                placeholder="Opportunity Name"
                disabled
                readOnly={true}
              />
            </Form.Item>
          </Tooltip>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Tooltip title="Source Requester">
            <Form.Item name="source_requestor">
              <Input
                autoFocus
                name="source_requestor"
                defaultValue={fields.source_requestor}
                // value={fields.source_requestor}
                // onChange={handleChange}
                disabled
                readOnly={true}
              />
            </Form.Item>
          </Tooltip>
        </Col>
      </Row>

      <Form.Item style={{ float: "right", margin: "10px 0" }}>
        <Button
          //   className="close-modal"
          type="primary"
          style={{ margin: "0 10px 0 0" }}
          // onClick={handleModalCancel}
        >
          Close
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="ok-modal"
          loading={saveLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddContributorForm;
