import React, { useEffect, useState } from "react";
import axios from "axios";
import BreadCrumbs from "../BreadCrumbs";
import {
  Row,
  Col,
  Table,
  Popconfirm,
  Button,
  Space,
  Tooltip,
  Form,
  Input,
  Modal,
  message,
  Tag,
} from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  DeleteTwoTone,
  EyeTwoTone,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { routes } from "../../util/constants";

const Estimation = (props) => {
  const [allEstimations, setAllEstimations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isNotApproved, setIsNotApproved] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getEstimation();
  }, []);
  const opp_status = props.location.state.record.statusId;
  const [fields, setFields] = useState({
    title: "",
    technology: "",
    source_requestor: localStorage.getItem("userEmail"),
    opportunity_name: props.location.state.record.name,
  });
  const opportunityId = props.location.state.record.opportunityId;
  console.log("opportunity status inside estimations", opp_status);
  const submitToBTG = async () => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/btg/submit-to-btg";
    console.log("role from local=======", localStorage.getItem("userRole"));
    const params = {
      email: localStorage.getItem("userEmail"),
      opportunity: props.location.state.record.name,
      role: localStorage.getItem("roleName"),
      currentStatus: props.location.state.record.statusId,
    };
    console.log("paramsss ", params);
    try {
      let res = await axios.post(url, params);
      console.log("response from submit to btg", res);

      return res;
    } catch (error) {
      console.log("error from get submit to btg", error);
    }
  };

  const navigateToBTGProposal = () => {
    console.log("entered btg");
    submitToBTG()
      .then((res) => {
        console.log("response befor navigating", res);
        message.success("Opportunity Submitted to BTG Team");
        // setIsNotApproved(false);
      })
      .catch((err) => {
        console.log("error from navigate to btg", err);
      });
  };
  const getEstimation = async () => {
    setLoading(true);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/estimation/getallestimation";
    const params = {
      opportunityId: opportunityId,
    };
    console.log("params", params);
    try {
      let result = await axios.get(url, { params });
      console.log("result from get all estimations", result.data);
      setAllEstimations(result.data);
      let flag = 0;
      result.data.map((est) => {
        if (est.status !== "APPROVED_ESTIMATION") {
          console.log("inside iff======");
          flag++;
        }
      });
      if (flag > 0) {
        setIsNotApproved(true);
      }
      setLoading(false);
    } catch (error) {
      console.log("error from get all estimations", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFields({ ...fields, [name]: value });
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const showAddEstimationModal = () => {
    setIsModalOpen(true);
  };

  const saveEstimation = async () => {
    setSaveLoading(true);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/estimation";
    const params = {
      opportunityId: opportunityId,
      title: fields.title,
      technology: fields.technology,
      estimatedBy: fields.source_requestor,
      hasSubTask: false,
    };
    try {
      let res = await axios.post(url, params);
      console.log("response from create estimation", res);
      setSaveLoading(false);
      getEstimation();
      setIsModalOpen(false);
    } catch (error) {
      console.log("error while creating estimation", error);
    }
  };

  const navigateToTaskEstimations = (record) => {
    navigate(routes.TASK_ESTIMATION, {
      state: {
        record,
      },
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      width: 100,
      render: (record) => <>{moment(record).format("MMMM Do YYYY")}</>,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 60,
    },
    {
      title: "Hours",
      dataIndex: "hours",
      width: 45,
      align: "center",
    },
    {
      title: "Estimated By",
      dataIndex: "estimatedByEmail",
      width: 120,
    },
    {
      title: "Technologies",
      dataIndex: "technology",
      width: 80,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        let color = "";
        if (record === "RE_ESTIMATION") color = "red";
        if (record === "APPROVED_ESTIMATION") color = "green";
        if (record === "ADDED_ESTIMATION") color = "gold";

        return (
          <>
            {" "}
            <Tag color={color}>{record}</Tag>
          </>
        );
      },
      width: 100,
    },
    {
      title: "Actions",
      align: "center",
      key: "action",
      width: 70,
      render: (record) => (
        <>
          <EyeTwoTone
            style={{ fontSize: "18px" }}
            className=""
            onClick={() => {
              navigateToTaskEstimations(record);
            }}
          />
          &nbsp;&nbsp;&nbsp;
          <Popconfirm
            placement="left"
            title="Are you sure, you want to delete the estimate"
            onConfirm={() => {
              // callDeleteEstimate(record);
            }}
            icon={
              <QuestionCircleOutlined className="my-icon .anticon svg  QuestionCircle-class " />
            }
          >
            <DeleteTwoTone
              twoToneColor="#d91d0f"
              style={{ fontSize: "18px" }}
            />{" "}
          </Popconfirm>
          <div />
        </>
      ),
    },
  ];
  let filteredColumns = columns.filter((col) => col.title !== "Actions");
  const new_col = {
    title: "Actions",
    align: "center",
    key: "action",
    width: 70,
    render: (record) => (
      <>
        <EyeTwoTone
          style={{ fontSize: "18px" }}
          className=""
          onClick={() => {
            navigateToTaskEstimations(record);
          }}
        />
      </>
    ),
  };
  filteredColumns.push(new_col);

  return (
    <>
      <div className="card-body ">
        <h1 className="page-title">Estimations</h1>
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        {localStorage.getItem("roleName") !== "Sales Representative" &&
          localStorage.getItem("roleName") !== "BTG" && (
            <div className="d-flex  justify-content-end mx-0">
              <Button
                type="primary mx-4"
                onClick={showAddEstimationModal}
                disabled={
                  opp_status === "CURRENTLY_WITH_BTG" ||
                  opp_status === "APPROVED"
                    ? true
                    : false
                }
              >
                <Space>Add Estimation</Space>
              </Button>
              <Popconfirm
                placement="left"
                title="Are you sure, you want to submit this proposal to BTG"
                onConfirm={() => {
                  navigateToBTGProposal();
                }}
              >
                <Button
                  className=" "
                  disabled={
                    (opp_status === "CURRENTLY_WITH_BTG" ||
                      opp_status === "APPROVED") &&
                    isNotApproved === false
                      ? true
                      : false
                  }
                >
                  <Space>Submit to BTG Team</Space>
                </Button>
              </Popconfirm>

              <Modal
                open={isModalOpen}
                // onOk={handleOk}
                onCancel={handleModalCancel}
                title="Create Estimation"
                style={{ top: 60 }}
                width={650}
                height={650}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose={true}
              >
                <Form
                  name="Create Estimation"
                  className="Create Estimation"
                  // form={form}
                  // onKeyDown={onKeyDownSubmit}
                  // onFinish={onSubmit}
                  initialValues={{
                    remember: true,
                  }}
                >
                  <Row gutter={[8, 8]}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip title="Enter Estimation Title">
                        <Form.Item
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter title!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus
                            placeholder="Enter Title"
                            name="title"
                            value={fields.title}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip title="Enter Technology">
                        <Form.Item
                          name="technology"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Technology!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus
                            placeholder="Enter Technology"
                            name="technology"
                            value={fields.technology}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip title="Source Requester">
                        <Form.Item name="source_requestor">
                          <Input
                            autoFocus
                            name="source_requestor"
                            defaultValue={fields.source_requestor}
                            value={fields.source_requestor}
                            onChange={handleChange}
                            disabled
                            readOnly={true}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip title="Opportunity Name">
                        <Form.Item name="opportunity_name">
                          <Input
                            autoFocus
                            name="opportunity_name"
                            defaultValue={fields.opportunity_name}
                            // value={fields.opportunity_name}
                            onChange={handleChange}
                            disabled
                            readOnly={true}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Form.Item style={{ float: "right", marginTop: "10px" }}>
                    <Button
                      type="primary"
                      style={{ margin: "0 10px 0 0" }}
                      htmlType="submit"
                      // className="ok-modal"
                      onClick={handleModalCancel}
                    >
                      Close
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ok-modal"
                      // onSubmit={saveEstimation}
                      onClick={saveEstimation}
                      loading={saveLoading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          )}

        <div className="  justify-content-end mx-5">
          <div className="card  mx-20 my-3 pt-20 ">
            {/* {pageLoading && (
                  <div className="d-flex justify-content-center">
                    <Spin size="medium" tip="Loading..."></Spin>
                  </div>
                )} */}
            {/* {!pageLoading && ( */}
            <Table
              scroll={{ y: 400 }}
              loading={loading}
              columns={
                opp_status === "CURRENTLY_WITH_BTG" || opp_status === "APPROVED"
                  ? filteredColumns
                  : columns
              }
              dataSource={allEstimations}
              className="table-striped-rows"
            />
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Estimation;
