import React, { Component, useEffect, useState } from "react";
import AddQuestion from "./AddQuestion";
import { Row } from "antd";

const FetchQuestion = (props) => {
  useEffect(() => {
    props.doAfterDisablingPage(props.pastQnaArray.length);
  }, [props.pastQnaArray]);

  return (
    <div className="question-box">
      <h4 class="fs-1 text-primary d-flex justify-content-center"></h4>

      <Row className="d-flex align-items-baseline px-3" gutter={[8, 16]}>
        {props.questions.map((question, index) => {
          // console.log(
          //   question,
          //   index
          //   //  props.toRenderObj
          // );
          return (
            <AddQuestion
              question={question}
              index={index}
              pastQnaArray={props?.pastQnaArray}
              disabled={props.pastQnaArray.length > 0 ? true : false}
              doAfterDisablingPage={props.doAfterDisablingPage}
              //   key={uuidv4()}
              //   toRenderObj={props.toRenderObj.questionAnswerObj}
              //   disable={props.toRenderObj.disable}
              reference={props.reference}
            />
          );
        })}
      </Row>
    </div>
  );
};

export default FetchQuestion;
