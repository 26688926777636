import axios from "axios";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import {
  Row,
  Col,
  Table,
  Popconfirm,
  Button,
  Space,
  Modal,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { DeleteTwoTone, QuestionCircleOutlined } from "@ant-design/icons";
import AddContributorForm from "../Forms/AddContributorForm";

const Contributors = (props) => {
  {
    console.log("propss inside contributor", props);
  }
  const opportunityId = props.location.state.record.opportunityId;
  const record = props.location.state.record;
  const [allContributors, setAllContributors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getAllContributors();
  }, []);
  const getAllContributors = async () => {
    setLoading(true);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/contributor";
    const params = {
      opportunityId: opportunityId,
    };
    try {
      let res = await axios.get(url, { params });
      console.log("all contributors==", res);
      setAllContributors(res.data);
      setLoading(false);

      setIsModalOpen(false);
    } catch (error) {
      console.log("error from all contributors", error);
    }
  };
  const showAddContributorModal = () => {
    setIsModalOpen(true);
  };

  const callDeleteContributor = async (record) => {
    console.log("record inside deletee", record);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/contributor";
    const params = {
      userEmail: localStorage.getItem("userEmail"),
      opportunityId: props.location.state.record.name,
      contributorEmail: record.email,
    };
    try {
      let res = await axios.delete(url, { params });
      console.log("response after deletion", res);
      if (res.response.status === 403)
        message.error("You are not eligible to delete this contributor");
      else message.success("contributor deleted");

      getAllContributors();
    } catch (error) {
      console.log("error deleting contrbutors", error);
    }
  };

  const getLatestList = (data) => {
    setAllContributors(data);
    setIsModalOpen(false);
  };
  const handleModalCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Date of addition",
      dataIndex: "createdAt",
      width: 100,
      render: (record) => <>{moment(record).format("MMMM Do YYYY")}</>,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 100,
    },
    {
      title: "Role",
      dataIndex: "roleId",
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      hidden: true,
      align: "center",
      width: 50,
      render: (record) => (
        <>
          <Popconfirm
            placement="left"
            title="Are you sure, you want to delete the contributor"
            onConfirm={() => {
              callDeleteContributor(record);
            }}
            icon={<QuestionCircleOutlined className="QuestionCircle-class " />}
          >
            <DeleteTwoTone
              twoToneColor="#d91d0f"
              style={{ fontSize: "18px" }}
            />{" "}
          </Popconfirm>
          {/* )} */}
        </>
      ),
    },
  ];
  const filteredColumns = columns.filter((col) => col.title !== "Action");
  return (
    <>
      <div className="card-body">
        <h1 className="page-title">Contributors</h1>
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-end mx-0">
          <Button
            type="primary"
            className=" "
            onClick={showAddContributorModal}
          >
            <Space>Add Contributor</Space>
          </Button>
          <Row>
            {" "}
            <Col span={24}>
              <Modal
                open={isModalOpen}
                // onOk={handleOk}
                onCancel={handleModalCancel}
                title="Add Contibutor"
                style={{ top: 20 }}
                width={650}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose={true}
              >
                <AddContributorForm
                  record={record}
                  getLatestList={getLatestList}
                />
              </Modal>
            </Col>
          </Row>
        </div>

        <div className="  justify-content-end mx-5">
          <div className="card  mx-20 my-3 pt-20 ">
            {/* {pageLoading && (
                  <div className="d-flex justify-content-center">
                    <Spin size="medium" tip="Loading..."></Spin>
                  </div>
                )} */}
            {/* {!pageLoading && ( */}
            <Table
              loading={loading}
              scroll={{ y: 400 }}
              columns={
                localStorage.getItem("roleName") === "Sales Representative"
                  ? columns
                  : filteredColumns
              }
              dataSource={allContributors}
              className="table-striped-rows"
            />
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contributors;
