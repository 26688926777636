import React, { useEffect, useState } from "react";
import {
  Upload,
  Button,
  message,
  Form,
  Input,
  Row,
  Col,
  Tooltip,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";

const AddDocumentForm = ({ record, getLatestList }) => {
  const [comments, setComments] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  useEffect(() => {
    console.log("record inside add docu", record);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setComments(value);
  };

  const propsForDocumentUpload = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      callBeforeUpload(file);

      return false; // Prevent automatic file upload
    },
    fileList: fileList,
    onRemove: (file) => {
      setFileList([]);
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      if (fileList.length === 0) {
        setFileList(info.fileList);
      }
    },
  };

  const callBeforeUpload = (file) => {
    setFileToUpload(file);
  };

  const onSubmit = () => {
    if (
      !propsForDocumentUpload.fileList ||
      propsForDocumentUpload.fileList.length === 0
    ) {
      message.error("No File Selected");
      return;
    }
    setSaveLoading(true);

    console.log("entered document submit", fileToUpload);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/document/presigned";
    const params = {
      name: fileToUpload.name,
      opportunityId: record.opportunityId,
      mime: fileToUpload.type,
      uploadedBy: localStorage.getItem("userEmail"),
      request_type: "PUT",
      comment: comments,
    };
    let result = "";
    axios
      .post(url, params)
      .then(async (res) => {
        console.log("response from pre signed url", res);
        result = res.data.body;
        if (!result) {
          console.log("pre signed url not available");
          message.error("facing error in uploading this file");
        }
        try {
          const signedRequestResponse = await fetch(result, {
            method: "PUT",
            body: fileToUpload,
          });
          console.log("signed req res", signedRequestResponse);

          if (signedRequestResponse.ok) {
            const url2 =
              "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/document/getalldocumentsbyopportunityid";
            const params_2 = {
              opportunityId: record.opportunityId,
            };
            console.log("params inside res", params_2);
            let allDoc = await axios.get(url2, { params: params_2 });
            getLatestList(allDoc.data);
            console.log("response from all doc", allDoc);
            message.success(`${fileToUpload.name} file uploaded successfully.`);
            setComments("");
            setSaveLoading(false);
          } else {
            message.error(`${fileToUpload.name} file upload failed.`);
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          message.error("An error occurred while uploading the file.");
        }
      })
      .catch((err) => {
        console.log("error while fetching pre signed url", err);
      });
  };
  return (
    <Form
      name="Add Document"
      className=""
      //   form={form}
      //   onKeyDown={onKeyDownSubmit}
      onFinish={onSubmit}
      initialValues={{
        remember: true,
      }}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item name="Upload" className="text-center">
            <Upload {...propsForDocumentUpload}>
              <Button icon={<UploadOutlined />}>
                Click to Upload Document
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item name="Comments" className="text-center">
            <Tooltip title="Comments">
              <TextArea
                rows={4}
                placeholder="Add comments if any..."
                name="comments"
                value={comments}
                onChange={handleChange}
              ></TextArea>
            </Tooltip>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ float: "right", margin: "10px 0" }}>
        <Button
          //   className="close-modal"
          type="primary"
          style={{ margin: "0 10px 0 0" }}
          // onClick={}
        >
          Close
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="ok-modal"
          loading={saveLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddDocumentForm;
