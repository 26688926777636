import logo from "../assests/img/icons8-globe-64.svg";
export const routes = {
  LOGIN: "/",
  ADMIN_DASHBOARD: "/dashboard",
  OPPORTUNITY: "/opportunity",
  ESTIMATION: "/opportunity/estimation",
  OPPORTUNITY_DETAILS: "/opportunity/details",
  TASK_ESTIMATION: "/opportunity/estimation/taskestimation",
  QUESTIONNAIRE: "/opportunity/questionnaire",
  CONTRIBUTORS: "/opportunity/details/contributors",
  DOCUMENTS: "/opportunity/details/documents",
  BTG_PROPOSAL_FORM: "/opportunity/proposal",
  SUBMIT_ESTIMATION: "/opportunity/estimation/proposal",
  TASK_ESTIMATION_THROUGH_BTG:
    "/opportunity/estimation/proposal/taskestimation",
  SALES_PROPOSAL_FORM: "/opportunity/details/clientproposal",
  BTG_PROPOSAL_THROUGH_DETAILS: "/opportunity/details/proposal",
  TASK_ESTIMATION_THROUGH_DETAILS_BTG: "/opportunity/details/propsal/task",
  QNA_THROUGH_SALES_PROPOSAL:
    "/opportunity/details/clientproposal/questionnaire",
  TASK_ESTIMATION_THROUGH_SALES: "/opportunity/estimation/clientproposal/task",
  QNA_THROUGH_BTG_PROPOSAL: "/opportunity/details/proposal/questionnaire",
};
export const brand = {
  NAME: "Opportunity",
  LOGO: (
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="35px" height="35px" baseProfile="basic">
    //   <linearGradient id="9yv_OTO3P9eepfu7Rtqera" x1="32" x2="32" y1="7" y2="58" gradientUnits="userSpaceOnUse">
    //     <stop offset="0" stop-color="#6dc7ff" />
    //     <stop offset=".699" stop-color="#e6abff" />
    //   </linearGradient>
    //   <path fill="url(#9yv_OTO3P9eepfu7Rtqera)" d="M53.27,26.96c0.28-1.05,0.42-2.11,0.42-3.17c0-6.86-5.58-12.43-12.43-12.43 c-0.77,0-1.56,0.07-2.35,0.23C36.54,8.67,33.06,7,29.28,7c-5.55,0-10.36,3.62-11.92,8.89C11.43,16.9,7.04,22.05,7.04,28.14 c0,3.45,1.43,6.72,3.93,9.07c-0.44,1.3-0.66,2.64-0.66,4c0,6.86,5.58,12.43,12.43,12.43c0.44,0,0.88-0.02,1.38-0.08 C26.5,56.39,29.93,58,33.63,58c5.88,0,10.96-4.19,12.16-9.88c6.32-0.63,11.17-5.91,11.17-12.35 C56.96,32.46,55.62,29.29,53.27,26.96z M38.25,35.88l-6.63,4l-6.5-4v-7.26l6.63-3.87l6.63,3.75L38.25,35.88z" />
    //   <linearGradient id="9yv_OTO3P9eepfu7Rtqerb" x1="32" x2="32" y1=".872" y2="62.679" gradientUnits="userSpaceOnUse">
    //     <stop offset="0" stop-color="#1a6dff" />
    //     <stop offset="1" stop-color="#c822ff" />
    //   </linearGradient>
    //   <path fill="url(#9yv_OTO3P9eepfu7Rtqerb)" d="M53.274,26.955 c0.275-1.045,0.415-2.107,0.415-3.166c0-6.855-5.578-12.434-12.434-12.434c-0.766,0-1.553,0.079-2.35,0.235 C36.536,8.665,33.062,7,29.278,7c-5.549,0-10.353,3.622-11.913,8.891c-5.93,1.012-10.32,6.163-10.32,12.254 c0,3.448,1.424,6.715,3.93,9.07c-0.44,1.299-0.664,2.64-0.664,3.996c0,6.855,5.578,12.434,12.434,12.434 c0.433,0,0.874-0.027,1.38-0.087C26.501,56.389,29.934,58,33.633,58c5.875,0,10.96-4.192,12.152-9.878 c6.327-0.629,11.17-5.908,11.17-12.355C56.956,32.463,55.622,29.286,53.274,26.955z M51.689,23.789c0,0.646-0.07,1.293-0.193,1.937 l-12.293-7.185l-13.146,7.991v-4.91l12.814-7.972c0.813-0.191,1.615-0.295,2.383-0.295C47.008,13.355,51.689,18.036,51.689,23.789z M37.397,35.171l-5.563,3.316l-5.776-3.303v-6.311l5.465-3.322l-0.031,0.052l5.905,3.48V35.171z M29.278,9 c2.997,0,5.755,1.251,7.728,3.457l-12.948,8.054V34.04l-4.898-2.801V16.922C20.319,12.254,24.462,9,29.278,9z M9.044,28.145 c0-4.923,3.419-9.109,8.116-10.169v14.424l12.701,7.264l-5.227,3.115l-11.897-6.674C10.392,34.107,9.044,31.211,9.044,28.145z M12.311,41.211c0-0.955,0.138-1.902,0.4-2.828l11.954,6.706l12.732-7.588v6.27l-13.172,7.754c-0.57,0.078-1.043,0.12-1.48,0.12 C16.992,51.645,12.311,46.964,12.311,41.211z M33.633,56c-2.886,0-5.578-1.175-7.546-3.252l13.31-7.835V30.261l4.539,2.675V47.09 C43.192,52.173,38.773,56,33.633,56z M45.936,46.091V31.793l-6.539-3.853v-0.068h-0.115l-5.879-3.465l5.821-3.538l12.309,7.195 c2.174,1.981,3.422,4.782,3.422,7.703C54.956,41.055,51.07,45.406,45.936,46.091z" />
    // </svg>

    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="32.000000pt"
      height="32.000000pt"
      viewBox="0 0 64.000000 64.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        fill="#1890ff"
        stroke="none"
      >
        <path
          d="M485 590 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
  -8 -4 -11 -10z"
        />
        <path
          d="M320 579 c0 -5 12 -9 28 -9 15 0 50 -11 78 -24 159 -74 190 -290 58
  -406 -53 -46 -99 -63 -171 -63 -116 1 -214 82 -239 201 -8 37 -13 47 -13 28
  -3 -93 82 -202 178 -230 68 -20 94 -20 160 0 130 38 213 198 169 325 -19 56
  -66 117 -112 144 -45 26 -136 49 -136 34z"
        />
        <path
          d="M55 560 c-40 -45 20 -105 65 -65 25 23 25 47 2 68 -24 22 -45 21 -67
  -3z m63 -26 c2 -11 -3 -26 -11 -33 -29 -24 -70 17 -46 46 17 20 53 12 57 -13z"
        />
        <path
          d="M248 536 c-58 -21 -87 -43 -118 -92 -67 -107 -40 -232 67 -308 104
  -73 267 -28 321 88 85 180 -87 377 -270 312z m62 -61 c0 -59 -5 -64 -55 -45
  -23 9 -25 12 -14 32 14 29 50 68 61 68 4 0 8 -25 8 -55z m58 33 c11 -13 25
  -35 31 -49 8 -24 7 -28 -12 -33 -52 -14 -57 -10 -57 49 0 30 4 55 8 55 5 0 18
  -10 30 -22z m-135 -23 c-19 -39 -22 -41 -47 -22 -17 13 -16 15 15 35 46 30 51
  28 32 -13z m222 -4 c3 -6 -3 -14 -13 -20 -16 -8 -21 -4 -35 25 l-17 34 29 -15
  c17 -8 32 -19 36 -24z m49 -59 c7 -15 17 -42 21 -59 l7 -33 -45 0 -44 0 -7 47
  c-5 42 -3 50 16 65 26 19 33 16 52 -20z m-314 13 c8 -10 10 -30 6 -60 -7 -45
  -8 -45 -47 -45 -46 0 -49 12 -19 78 20 44 39 52 60 27z m120 -72 c0 -33 0 -33
  -51 -33 l-51 0 6 31 c3 17 6 37 6 43 0 9 11 10 45 2 42 -10 45 -13 45 -43z
  m108 4 l3 -37 -45 0 c-46 0 -46 0 -46 34 0 30 4 35 28 39 50 8 57 4 60 -36z
  m-216 -102 c3 -39 0 -48 -19 -60 -31 -21 -32 -20 -53 28 -31 67 -27 77 24 77
  l44 0 4 -45z m108 17 c0 -26 -5 -30 -44 -39 l-44 -10 -4 39 -4 38 48 0 c46 0
  48 -1 48 -28z m110 -5 c0 -44 -4 -46 -50 -35 -35 9 -40 14 -40 39 0 28 2 29
  45 29 44 0 45 0 45 -33z m98 -5 c-5 -20 -17 -47 -26 -59 -16 -21 -18 -21 -38
  -5 -17 14 -20 25 -16 60 l4 42 43 0 43 0 -10 -38z m-208 -98 c0 -47 -3 -55
  -17 -52 -17 3 -63 62 -63 80 0 10 37 26 63 27 14 1 17 -8 17 -55z m83 36 c14
  -13 -33 -90 -55 -90 -4 0 -8 25 -8 56 l0 56 28 -7 c15 -3 31 -10 35 -15z m58
  -26 c14 -17 12 -20 -44 -50 l-28 -15 23 40 c26 46 30 48 49 25z m-219 -24 c9
  -17 14 -30 10 -30 -6 0 -39 20 -61 36 -1 1 0 7 4 13 11 19 30 12 47 -19z"
        />
        <path
          d="M60 430 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4
  -4 -4 -10z"
        />
        <path
          d="M535 126 c-20 -15 -17 -44 7 -59 12 -8 21 -7 32 2 36 30 -1 85 -39
  57z m43 -29 c2 -11 -3 -17 -17 -17 -23 0 -35 15 -26 31 10 15 39 6 43 -14z"
        />
      </g>
    </svg>
  ),
  // LOGO: logo
};
