import {
  Button,
  Col,
  Row,
  Space,
  Typography,
  Divider,
  Table,
  Select,
  Tooltip,
  Popconfirm,
  notification,
  FloatButton,
  Statistic,
  message,
  Card,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import {
  CheckOutlined,
  DeleteTwoTone,
  EditOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  LikeOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { routes } from "../../util/constants";

const BTGProposalForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [allEstimations, setAllEstimations] = useState([]);
  const [proposalAdded, setProposalAdded] = useState(true);
  const [reEstimate, setReEstimate] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [totalResourceCount, setTotalResourceCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalRate, setTotalRate] = useState(0);
  const [roleName, setRoleName] = useState("");
  const [sentToSales, setSentToSales] = useState(false);
  const record = props.location.state.record;
  const navigate = useNavigate();
  useEffect(() => {
    getAllEstimations();
    getAllRoles();
    console.log("props inside btg proposal form", record);
    console.log("local storageee", localStorage.getItem("roleName"));
    setRoleName(localStorage.getItem("roleName"));
    if (localStorage.getItem("roleName") !== "BTG") setReEstimate(true);
    if (record.statusId === "APPROVED") setReEstimate(true);
  }, []);

  const getAllEstimations = async () => {
    setLoading(true);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/btg/opportunityresource";
    const params = {
      opportunityId: record.opportunityId,
    };
    try {
      let res = await axios.get(url, { params });
      console.log("response from get all allocations", res);
      if (res.data.length === 0) setProposalAdded(false);
      setAllEstimations(res.data);
      addFlagInItem(res.data);
      let hours = 0;
      let rate = 0;
      let count = 0;
      let amount = 0;
      res.data.map((item) => {
        hours = hours + item.hours;
        rate = rate + item.Rate;
        count = count + item.ResourceCount;

        amount = amount + rate * hours;
      });
      setTotalHours(hours);
      setTotalRate(rate);
      setTotalResourceCount(count);
      setTotalAmount(amount);
      setLoading(false);
    } catch (err) {
      console.log("error while fetching estimations", err);
    }
  };
  const navigateToQuestionnaire = () => {
    navigate(routes.QNA_THROUGH_BTG_PROPOSAL, {
      state: { record },
    });
  };
  const addFlagInItem = async (estimationList) => {
    try {
      await estimationList.forEach((value, index, array) => {
        value["isEnable"] = false;
        value["reEstimate"] = false;
      });
      setRows(estimationList);
      console.log("added flag");
      console.log("etimation list after flag", estimationList);
    } catch (err) {
      console.log("error from flag ", err);
    }
  };

  const callOnRowEdit = (record) => {
    let temp_rows = [...rows];
    console.log("callOnRowEdit====>", record);
    const currentIndex = temp_rows.indexOf(
      temp_rows.find((e) => e.estimationId == record.estimationId)
    );
    const enabled = temp_rows[currentIndex]["isEnable"];
    temp_rows[currentIndex]["isEnable"] = !enabled;
    setRows(temp_rows);
  };

  const getAllRoles = async () => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/role";
    try {
      let res = await axios.get(url);
      console.log("response from get all roles", res);
      let roles = [];
      res.data.map((item) => {
        let rolesObj = {};

        rolesObj["value"] = item.roleName;
        rolesObj["label"] = item.roleName;
        roles.push(rolesObj);
      });
      console.log("rolesss", roles);
      setAllRoles(roles);
    } catch (error) {
      console.log("error from get all roles", error);
    }
  };
  const submitProposalToSales = async () => {
    console.log("rows inside submit proposal", allEstimations);
    console.log("inside submit proposal to sales");
    let flag = 0;
    rows.map((item) => {
      if (
        !item.ResourceDesignation ||
        item.ResourceCount === 0 ||
        !item.location ||
        !item.Allocation ||
        item.Rate === 0
      ) {
        flag++;
        return;
      }
    });
    if (flag > 0) {
      console.log("inside if of flag");
      message.error("Please fill all the estmation details before proceeding");
    } else {
      setSubmitLoading(true);
      const url =
        "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/btg/approval";
      const params = {
        email: localStorage.getItem("userEmail"),
        opportunity: record.name,
      };
      console.log("params inside submit", params);
      try {
        let res = await axios.post(url, params);
        console.log("res from send proposal", res);
        setSubmitLoading(false);
        setReEstimate(true);
        setSentToSales(true);
        message.success("Proposal submitted to sales");
      } catch (error) {
        console.log("error from send proposal", error);
      }
    }
  };

  const getTotalHoursAndCost = () => {};
  const callCreateOrUpdateEstimation = async (record) => {
    // setEditingEnabled(true);
    console.log("record inside update", record);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/btg/resourceallocation";
    let params = {};
    if (record.resourceId === 0) {
      params = {
        rate: record.Rate,
        allocationType: record.Allocation,
        resourceDesignation: record.ResourceDesignation,
        estimationId: record.estimationId,
        allocationCount: record.ResourceCount,
        location: record.location,
      };
    } else {
      params = {
        id: record.resourceId,
        rate: record.Rate,
        allocationType: record.Allocation,
        resourceDesignation: record.ResourceDesignation,
        estimationId: record.estimationId,
        allocationCount: record.ResourceCount,
        location: record.location,
      };
    }

    try {
      let res = await axios.post(url, params);
      console.log("response from create est", res);
      let temp_rows = [...rows];
      console.log("callOnRowEdit====>", record);
      const currentIndex = temp_rows.indexOf(
        temp_rows.find((e) => e.estimationId == record.estimationId)
      );
      const enabled = temp_rows[currentIndex]["isEnable"];
      temp_rows[currentIndex]["isEnable"] = !enabled;
      setRows(temp_rows);
      // setEditingEnabled(false);
      getAllEstimations();
    } catch (error) {
      console.log("error from create est", error);
    }
  };
  const handleChange = (record) => (e) => {
    const { name, value } = e.target;
    const row = [...rows];
    // console.log("handleChangeName====>", name);
    // console.log("handleChangeName====>", value);
    // console.log("handleChangeEvent====>", e);
    console.log("inside handleChange", name, value);
    const currentIndex = row.indexOf(
      row.find((e) => e.estimationId == record.estimationId)
    );

    row[currentIndex][name] = value;

    console.log("handleChangeRow====>", row);
    setRows(row);
  };

  const navigateToTaskEstimations = (record) => {
    record["viewOnly"] = true;
    navigate(routes.TASK_ESTIMATION_THROUGH_BTG, {
      state: {
        record,
      },
    });
  };

  const sendForReEstimation = async (rec) => {
    console.log("record inside send for re estimation", record);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/estimation/re-estimation";
    const params = {
      estimationId: rec.estimationId,
      opportunityId: record.opportunityId,
    };
    try {
      let res = await axios.post(url, params);
      console.log("response from re-estimate api", res);
      setReEstimate(true); //for disabling submit proposal to sales button
      console.log("record inside re estimate", record);
      let temp_rows = [...rows];
      console.log("callOnRowEdit====>", record);
      const currentIndex = temp_rows.indexOf(
        temp_rows.find((e) => e.estimationId == record.estimationId)
      );
      const enabled = temp_rows[currentIndex]["reEstimate"];
      temp_rows[currentIndex]["reEstimate"] = !enabled; //for changing the re estimate icon in actions column
      setRows(temp_rows);
    } catch (err) {
      console.log("error from re estimation api", err);
    }
  };

  const handleLocationChange = (value, idx) => {
    console.log("inside handle location", value, idx);
    const row = [...rows];
    const currentIndex = row.indexOf(
      row.find((e) => e.estimationId == idx.estimationId)
    );

    row[currentIndex]["location"] = value;
    // console.log("handleChangeRow====>", row);
    setRows(row);
  };
  const handleAllocationChange = (value, idx) => {
    const row = [...rows];
    const currentIndex = row.indexOf(
      row.find((e) => e.estimationId == idx.estimationId)
    );

    row[currentIndex]["Allocation"] = value;
    // console.log("handleChangeRow====>", row);
    setRows(row);
  };
  const handleDesignationChange = (value, idx) => {
    const row = [...rows];
    const currentIndex = row.indexOf(
      row.find((e) => e.estimationId == idx.estimationId)
    );

    row[currentIndex]["ResourceDesignation"] = value;
    // console.log("handleChangeRow====>", row);
    setRows(row);
  };
  const location = [
    {
      value: "onshore",
      label: "Onshore",
    },
    {
      value: "offshore",
      label: "Offshore",
    },
  ];
  const allocation = [
    {
      value: "fulltime",
      label: "Full Time",
    },
    {
      value: "parttime",
      label: "Part Time",
    },
  ];

  const columns = [
    {
      title: "Estimation",
      width: 120,
      // dataIndex: "title",
      key: "title",
      fixed: "left",
      render: (text, record, index) => {
        return (
          <Typography.Text
            className="underline-effect"
            onClick={() => {
              navigateToTaskEstimations(record);
            }}
          >
            {record?.title}
          </Typography.Text>
        );
      },
    },
    {
      title: "Status",
      width: 180,
      dataIndex: "status",
      // key: "status",
      render: (record) => {
        let color = "";
        if (record === "RE_ESTIMATION") color = "red";
        if (record === "APPROVED_ESTIMATION") color = "green";
        if (record === "ADDED_ESTIMATION") color = "gold";

        return (
          <>
            {" "}
            <Tag color={color}>{record}</Tag>
          </>
        );
      },
      fixed: "left",
    },
    {
      title: "Resource Designation",
      //   dataIndex: "designation",
      //   key: "designation",
      width: 200,
      render: (record, idx) => (
        <>
          <Select
            defaultValue={
              record.ResourceDesignation
                ? record.ResourceDesignation
                : "Designation"
            }
            style={{
              width: 180,
            }}
            onChange={(value) => {
              handleDesignationChange(value, idx);
            }}
            options={allRoles}
            disabled={!idx.isEnable}
          />
        </>
      ),
    },
    {
      title: "Resource Count",
      dataIndex: "ResourceCount",
      width: 100,
      align: "center",

      render: (text, record, index) => (
        <input
          type="number"
          min="0"
          name="ResourceCount"
          placeholder="Count"
          value={record.ResourceCount !== 0 ? record.ResourceCount : null}
          onChange={handleChange(record)}
          className="estimate-input"
          style={{ width: "100%" }}
          disabled={record.isEnable ? "" : "disabled"}
        />
      ),
    },
    {
      title: "Location",
      //   dataIndex: "location",
      //   key: "location",
      width: 140,
      render: (record, idx) => (
        <>
          <Select
            defaultValue={record.location ? record.location : "Location"}
            style={{
              width: 120,
            }}
            onChange={(value) => {
              handleLocationChange(value, idx);
            }}
            options={location}
            disabled={!idx.isEnable}
          />
        </>
      ),
    },
    {
      title: "Allocation",
      //   dataIndex: "allocation",
      //   key: "allocation",
      width: 140,
      render: (record, idx) => (
        <>
          <Select
            defaultValue={record.Allocation ? record.Allocation : "Allocation"}
            style={{
              width: 120,
            }}
            onChange={(value) => {
              handleAllocationChange(value, idx);
            }}
            options={allocation}
            disabled={!idx.isEnable}
          />
        </>
      ),
    },
    {
      title: "Rate($)",
      dataIndex: "Rate",
      width: 100,
      align: "center",
      render: (text, record, index) => (
        <input
          type="number"
          min="0"
          name="Rate"
          placeholder="Rate"
          value={record.Rate !== 0 ? record.Rate : null}
          onChange={handleChange(record)}
          className="estimate-input"
          style={{ width: "100%" }}
          disabled={record.isEnable ? "" : "disabled"}
        />
      ),
      //   key: "Rate",
    },
    {
      title: "Hours",
      dataIndex: "hours",
      width: 80,
      align: "center",
      key: "hours",
    },
    {
      title: "Sub Total($)",
      dataIndex: "subTotal",
      width: 100,
      align: "center",
      render: (item, idx) => (
        <>
          <input
            type="number"
            min="0"
            name="subTotal"
            className="btgTable"
            style={{
              width: 60,
            }}
            placeholder="Sub Total"
            value={idx.Rate * idx.hours}
            onChange={handleChange(idx)}
            disabled
          />
        </>
      ),
      //   key: "subTotal",
    },

    {
      title: "Actions",
      // key: 'operation',
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <>
            <div>
              {record.isEnable === true && (
                <Tooltip title="Save Changes">
                  {editingEnabled === true ? (
                    <LoadingOutlined className="icon-class" />
                  ) : (
                    <CheckOutlined
                      className="icon-class"
                      onClick={() => {
                        callCreateOrUpdateEstimation(record);
                      }}
                    />
                  )}
                </Tooltip>
              )}
              {record.isEnable === false && (
                <Tooltip title="Edit">
                  <EditOutlined
                    className="icon-class"
                    onClick={() => {
                      callOnRowEdit(record);
                    }}
                  />
                </Tooltip>
              )}
              &nbsp;
              <Tooltip title="Delete">
                <Popconfirm
                  placement="left"
                  title="Are you sure, you want to delete the task"
                  onConfirm={() => {
                    //   callDeleteTaskEstimation(record);
                  }}
                  icon={
                    <QuestionCircleOutlined className="QuestionCircle-class " />
                  }
                >
                  <DeleteTwoTone
                    twoToneColor="#d91d0f"
                    style={{ fontSize: "18px" }}
                  />
                </Popconfirm>{" "}
              </Tooltip>
              &nbsp;
              {record.reEstimate === true && (
                <Tooltip title="Sent for re-estimation">
                  <ExclamationCircleTwoTone
                    twoToneColor="#d91d0f"
                    style={{ fontSize: "18px" }}
                  />
                </Tooltip>
              )}
              {record.reEstimate === false && (
                <Tooltip title="Re-Estimate">
                  <Popconfirm
                    placement="left"
                    title="Are you sure, you want this estimation to get re-estimated"
                    onConfirm={() => {
                      sendForReEstimation(record);
                    }}
                    icon={
                      <QuestionCircleOutlined className="QuestionCircle-class " />
                    }
                  >
                    <RetweetOutlined style={{ fontSize: "18px" }} />
                  </Popconfirm>
                </Tooltip>
              )}
            </div>
            {/* // )} */}
          </>
        );
      },
    },
  ];
  const filteredColumns = columns.filter((col) => col.title !== "Actions");

  const DetailItem = ({ title, detail }) => {
    return (
      <div>
        <Col>
          <Typography.Text
            style={{
              fontSize: "13px",
              color: "grey",
            }}
          >
            {title}
          </Typography.Text>
          <Typography.Title
            style={{
              color: "rgb(105 105 105)",
              margin: 0,
            }}
            level={5}
          >
            {detail}
          </Typography.Title>
        </Col>
      </div>
    );
  };
  return (
    <>
      <div className="card-body pt-3">
        <h1 className="page-title">Proposal</h1>
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row ">
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <div className="d-flex  justify-content-end mx-0 ">
          <Button
            // type="primary"
            className=""
            onClick={navigateToQuestionnaire}
          >
            <Space>View Questionnaire</Space>
          </Button>
        </div>

        {/* <div className="justify-content-end mx-5"> */}
        <div className="card  mx-20 my-3 pt-20 detail-card">
          <Row gutter={[12, 12]}>
            <Col>
              {/* <div className="card" style={{ margin: "10px 0 0 0" }}> */}
              <div className="card-body" style={{ paddingBottom: "14px" }}>
                <div>
                  <h4 className="page-title">Opportunity Details</h4>
                  {/* <Col>
                    <Typography.Text
                      style={{
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Opportunity:
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "rgb(105 105 105)",
                        marginTop: "-6px",
                        marginBottom: "-12px",
                      }}
                      level={2}
                    >
                      {props.location.state.record.name}
                    </Typography.Title>
                  </Col> */}
                  <Col></Col>

                  <Divider />
                </div>

                <Row gutter={[16, 20]}>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Opportunity Name"}
                      detail={props.location.state.record.name}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Client Name"}
                      detail={props.location.state.record.clientName}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Client Email"}
                      detail={props.location.state.record.clientEmail}
                    />
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Sales"}
                      detail={props.location.state.record.salesId}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Created At"}
                      detail={moment(
                        props.location.state.record.createdAt
                      ).format("MMMM Do YYYY, h:mm:ss a")}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Creator's Name"}
                      detail={props.location.state.record.createdBy}
                    />
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Submission Date"}
                      detail={moment(
                        props.location.state.record.submittedDate
                      ).format("MMMM Do YYYY, h:mm:ss a")}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Updation Date"}
                      detail={moment(
                        props.location.state.record.updatedAt
                      ).format("MMMM Do YYYY, h:mm:ss a")}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Updater's Name"}
                      detail={props.location.state.record.lastEditedBy}
                    />
                  </Col>

                  <Col className="gutter-row" span={12}>
                    <DetailItem
                      title={"Status"}
                      detail={props.location.state.record.statusId}
                    />
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <DetailItem
                      title={"Description"}
                      detail={props.location.state.record.description}
                    />
                  </Col>
                </Row>
                <Divider />
                <div style={{ marginTop: "3%" }}>
                  <h2 className="page-title">Cost As Per Estimations</h2>
                  <div className="d-flex  justify-content-end mx-0 ">
                    {/* <FloatButton
                      description="HELP INFO"
                      shape="square"
                      style={{
                        right: 94,
                      }}
                    /> */}
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Table
                    columns={
                      record.statusId === "APPROVED" || sentToSales
                        ? filteredColumns
                        : columns
                    }
                    loading={loading}
                    dataSource={rows}
                    // scroll={{
                    //   x: 1300,
                    // }}
                    scroll={{
                      x: "calc(700px + 50%)",
                      y: 240,
                    }}
                  />{" "}
                  <div>
                    <Row gutter={16}>
                      <Col span={6}>
                        <Card bordered={false}>
                          <Statistic
                            title="Total Resource Count"
                            value={totalResourceCount}
                            // precision={2}
                            valueStyle={{
                              color: "#3d5170",
                            }}
                            prefix={<TeamOutlined />}
                          />
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card bordered={false}>
                          <Statistic
                            title="Total Rate($)"
                            value={totalRate}
                            precision={2}
                            valueStyle={{
                              color: "#3d5170",
                            }}
                            // prefix={<DollarOutlined />}
                            suffix="$"
                          />
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card bordered={false}>
                          <Statistic
                            title="Total Hours"
                            value={totalHours}
                            precision={2}
                            valueStyle={{
                              color: "#3d5170",
                            }}
                            // prefix={<FieldTimeOutlined />}
                          />
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card bordered={false}>
                          <Statistic
                            title="Sub Total($)"
                            value={totalAmount}
                            precision={2}
                            valueStyle={{
                              color: "#3d5170",
                            }}
                            // prefix={<CheckCircleOutlined />}
                            suffix="$"
                          />
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              {/* </div> */}
            </Col>
          </Row>
        </div>

        <div className="mx-0 text-center">
          <Button
            type="primary"
            disabled={reEstimate}
            onClick={submitProposalToSales}
            loading={submitLoading}
          >
            Submit Proposal To Sales
          </Button>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default BTGProposalForm;
