import { useEffect, useState } from "react";
import React from "react";
import { Line, Column } from "@ant-design/plots";
import { Col, Row } from "antd";
import axios from "axios";

const OpportunityGraph = () => {
  const data = [
    {
      type: "家具家电",
      sales: 38,
    },
    {
      type: "粮油副食",
      sales: 52,
    },
    {
      type: "生鲜水果",
      sales: 61,
    },
    {
      type: "美容洗护",
      sales: 145,
    },
    {
      type: "母婴用品",
      sales: 48,
    },
    {
      type: "进口食品",
      sales: 38,
    },
    {
      type: "食品饮料",
      sales: 38,
    },
    {
      type: "家庭清洁",
      sales: 38,
    },
  ];
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    getOpportunityCount();
  }, []);
  const getOpportunityCount = () => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/dashboard/monthlyopportunity";
    // try {
    //   let res = await axios.get(url);
    //   console.log("response from get all opp count", res.data.body[0]);
    //   const result = res.data.body[0];
    //   let arr = [];
    //   Object.keys(result).map((key) => {
    //     let obj = {};
    //     obj["Month"] = key;
    //     obj["Count"] = result[key];
    //     arr.push(obj);
    //   });
    //   console.log("final obj for chart", arr);
    //   setChartData(arr);
    // } catch (error) {
    //   console.log("response from get opp count", error);
    // }
    axios
      .get(url)
      .then((res) => {
        console.log("response from get all opp count", res.data.body[0]);
        const result = res.data.body[0];
        let arr = [];
        Object.keys(result).map((key) => {
          let obj = {};
          obj["Month"] = key;
          obj["Count"] = result[key];
          arr.push(obj);
        });
        console.log("final obj for chart", arr);
        setChartData(arr);
      })
      .catch((err) => {
        console.log("eror from get opp count", err);
      });
  };

  // const config = {
  //   data,
  //   xField: "type",
  //   yField: "sales",
  //   columnWidthRatio: 0.2,
  //   // color: "3f3f4e",
  //   color: ({ type }) => {
  //     return "#3d5170";
  //   },
  //   xAxis: {
  //     label: {
  //       autoHide: true,
  //       autoRotate: false,
  //     },
  //   },
  //   meta: {
  //     type: {
  //       alias: "类别",
  //     },
  //     sales: {
  //       alias: "销售额",
  //     },
  //   },
  // };

  const config = {
    data: chartData,
    xField: "Month",
    yField: "Count",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    columnWidthRatio: 0.2,
    color: ({ type }) => {
      return "#3d5170";
    },
  };
  return (
    <div className="justify-content-end mx-5">
      {/* <div className="card container-dash-g pt-20 my-3"> */}
      <div className="card container-dash-g">
        <div className="dash-graph-line card-body">
          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="d-flex  mb-2">
                <h5 className="text-secondary" style={{ fontSize: "2.1rem" }}>
                  Total Opportunities
                </h5>
                <h6
                  className="text-secondary mt-3 ms-4"
                  style={{ fontSize: "1.2rem" }}
                >
                  Last 12 Months
                </h6>
              </div>
            </Col>
          </Row>
          <div className="graph-div">
            <Column {...config} />
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default OpportunityGraph;
