import React, { useState } from "react";
import { Layout } from "antd";
import HeaderAdmin from "../layouts/HeaderAdmin";
import SiderMenu from "./SiderMenu";
import PropTypes from "prop-types";
import moment from "moment";
import { routes } from "../../util/constants";
import Dashboard from "./Dashboard";

import { useLocation } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Opportunity from "../Opportunity/Opportunity";
import Estimation from "../Opportunity/Estimation";
import OpportunityDetails from "../Opportunity/OpportunityDetails";
import TaskEstimation from "../Opportunity/TaskEstimation";
import Questionnaire from "../Opportunity/Questionnaire";
import Contributors from "../Opportunity/Contributors";
import Documents from "../Opportunity/Documents";
import BTGProposalForm from "../Forms/BTGProposalForm";
import SalesProposal from "../Opportunity/SalesProposal";

const { Sider, Content, Footer } = Layout;

const AdminDashboard = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [sideCollapsed, setSideCollapsed] = useState(false);
  const [viewToggleBtn, setViewToggleBtn] = useState(true);
  const location = useLocation();

  // console.log("Propsssssssssssssssss", props);

  const toggle = () => {
    setCollapsed(!collapsed);
    setSideCollapsed(false);
  };

  const handleMouseEnter = () => {
    setViewToggleBtn(true);
  };

  const handleMouseLeave = () => {
    setViewToggleBtn(false);
  };

  const { pathname } = props.location;

  function renderPathName(pathname) {
    switch (pathname) {
      case routes.OPPORTUNITY:
        return <Opportunity location={location} />;
      case routes.ADMIN_DASHBOARD:
        return <Dashboard location={location} />;
      case routes.ESTIMATION:
        return <Estimation location={location} />;
      case routes.OPPORTUNITY_DETAILS:
        return <OpportunityDetails location={location} />;
      case routes.TASK_ESTIMATION:
        return <TaskEstimation location={location} />;
      case routes.QUESTIONNAIRE:
        return <Questionnaire location={location} />;
      case routes.CONTRIBUTORS:
        return <Contributors location={location} />;
      case routes.DOCUMENTS:
        return <Documents location={location} />;
      case routes.BTG_PROPOSAL_FORM:
        return <BTGProposalForm location={location} />;
      case routes.SUBMIT_ESTIMATION:
        return <BTGProposalForm location={location} />;
      case routes.TASK_ESTIMATION_THROUGH_BTG:
        return <TaskEstimation location={location} />;
      case routes.TASK_ESTIMATION_THROUGH_SALES:
        return <TaskEstimation location={location} />;
      case routes.SALES_PROPOSAL_FORM:
        return <SalesProposal location={location} />;
      case routes.QNA_THROUGH_SALES_PROPOSAL:
        return <Questionnaire location={location} />;
      case routes.QNA_THROUGH_BTG_PROPOSAL:
        return <Questionnaire location={location} />;
      case routes.BTG_PROPOSAL_THROUGH_DETAILS:
        return <BTGProposalForm location={location} />;

      default:
        return <Dashboard location={location} />;
    }
  }

  return (
    <>
      <Layout className="admin-dash ">
        <Sider
          width={260}
          breakpoint="sm"
          collapsedWidth={sideCollapsed ? "80" : "0"}
          theme="light"
          trigger={null}
          collapsible
          collapsed={sideCollapsed ? sideCollapsed : collapsed}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={collapsed ? "display-switch" : ""}>
            <div className={!sideCollapsed ? "toogle-off" : "toogle-on"}>
              <span
                className={
                  sideCollapsed && !viewToggleBtn
                    ? "collapsed-switch-visible"
                    : ""
                }
              ></span>
            </div>
          </div>
          <SiderMenu
            history={props.history}
            user={props.user}
            collapsed={collapsed}
          ></SiderMenu>
        </Sider>
        <Layout className="site-layout">
          <HeaderAdmin
            toggle={toggle}
            collapsed={collapsed}
            user={props.user}
            signOut={props.signOut}
          />
          <Content
            style={{
              margin: "30px",
              minHeight: "100vh",
            }}
          >
            {renderPathName(pathname)}
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            ©{moment().year()} Created by EAST-Team
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};
AdminDashboard.propTypes = {
  location: PropTypes.object.isRequired,
};
export default withAuthenticator(AdminDashboard);
