import React, { useEffect } from "react";
import axios from "axios";
import BreadCrumbs from "../BreadCrumbs";
import {
  Row,
  Col,
  Table,
  Typography,
  Divider,
  Button,
  Tooltip,
  Space,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { routes } from "../../util/constants";
import { FileProtectOutlined, RightCircleOutlined } from "@ant-design/icons";
import { facebookSignInButton } from "aws-amplify";

const OpportunityDetails = (props) => {
  const navigate = useNavigate();
  const opportunityId = props.location.state.record.opportunityId;
  const record = props.location.state.record;
  const userRole = localStorage.getItem("roleName");
  useEffect(() => {
    console.log("propssssss", props.location.state);
    console.log("user role", localStorage.getItem("roleName"));
  }, []);
  const navigateToEstimations = () => {
    navigate(routes.ESTIMATION, {
      state: { record },
    });
  };

  const navigateToContributors = () => {
    navigate(routes.CONTRIBUTORS, {
      state: { record },
    });
  };
  const navigateToProposal = () => {
    if (localStorage.getItem("roleName") === "Sales Representative") {
      navigate(routes.SALES_PROPOSAL_FORM, {
        state: { record },
      });
    } else {
      navigate(routes.BTG_PROPOSAL_THROUGH_DETAILS, {
        state: { record },
      });
    }
  };
  const navigateToDocuments = () => {
    navigate(routes.DOCUMENTS, {
      state: { record },
    });
  };

  const navigateToQuestionnaire = () => {
    navigate(routes.QUESTIONNAIRE, {
      state: { record },
    });
  };

  const DetailItem = ({ title, detail }) => {
    return (
      <div>
        <Col>
          <Typography.Text
            style={{
              fontSize: "13px",
              color: "grey",
            }}
          >
            {title}
          </Typography.Text>
          <Typography.Title
            style={{
              color: "rgb(105 105 105)",
              margin: 0,
            }}
            level={5}
          >
            {detail}
          </Typography.Title>
        </Col>
      </div>
    );
  };
  return (
    <>
      <div className="card-body pt-3">
        <h1 className="page-title">Details</h1>
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row ">
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <div className="d-flex  justify-content-end mx-0 ">
          {(localStorage.getItem("roleName") === "BTG" ||
            localStorage.getItem("roleName") === "Sales Representative") && (
            <Button
              type="primary"
              onClick={navigateToProposal}
              disabled={
                localStorage.getItem("roleName") === "Sales Representative" &&
                record.statusId !== "APPROVED"
              }
            >
              Go To Proposal <FileProtectOutlined />
            </Button>
          )}
          {userRole !== "BTG" && userRole !== "Sales Representative" && (
            <Button type="primary" onClick={navigateToQuestionnaire}>
              Open Questionnaire <RightCircleOutlined />
            </Button>
          )}
        </div>
        <div className="card  mx-20 my-3 pt-20 detail-card">
          <Row gutter={[12, 12]}>
            <Col>
              {/* <div className="card" style={{ margin: "10px 0 0 0" }}> */}
              <div className="card-body" style={{ paddingBottom: "14px" }}>
                <div>
                  <Col>
                    <Typography.Text
                      style={{
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Opportunity:
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "rgb(105 105 105)",
                        marginTop: "-6px",
                        marginBottom: "-12px",
                      }}
                      level={2}
                    >
                      {props.location.state.record.name}
                    </Typography.Title>
                  </Col>
                  <Col>
                    <div className="d-flex  justify-content-end mx-5 ">
                      <Button
                        // type="primary"
                        className=" "
                        onClick={navigateToContributors}
                      >
                        <Space>View Contributors</Space>
                      </Button>
                      {"  "}
                      <Button
                        // type="primary"
                        className=" "
                        onClick={navigateToDocuments}
                        style={{ marginLeft: "5px" }}
                      >
                        <Space>View Documents</Space>
                      </Button>
                      <Button
                        // type="primary"
                        className=" "
                        onClick={navigateToEstimations}
                        style={{ marginLeft: "5px" }}
                      >
                        <Space>View Estimations</Space>
                      </Button>
                    </div>
                  </Col>

                  <Divider />
                </div>

                <Row gutter={[16, 20]}>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Client Name"}
                      detail={props.location.state.record.clientName}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Client Email"}
                      detail={props.location.state.record.clientEmail}
                    />
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Sales"}
                      detail={props.location.state.record.salesId}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Created At"}
                      detail={moment(
                        props.location.state.record.createdAt
                      ).format("MMMM Do YYYY, h:mm:ss a")}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Creator's Name"}
                      detail={props.location.state.record.createdBy}
                    />
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Submission Date"}
                      detail={moment(
                        props.location.state.record.submittedDate
                      ).format("MMMM Do YYYY")}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Updation Date"}
                      detail={moment(
                        props.location.state.record.updatedAt
                      ).format("MMMM Do YYYY")}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Updater's Name"}
                      detail={props.location.state.record.lastEditedBy}
                    />
                  </Col>

                  <Col className="gutter-row" span={12}>
                    <DetailItem
                      title={"Status"}
                      detail={props.location.state.record.statusId}
                    />
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <DetailItem
                      title={"Description"}
                      detail={props.location.state.record.description}
                    />
                  </Col>
                </Row>
              </div>

              {/* </div> */}
            </Col>
          </Row>
        </div>
        {/* <div className="mx-0 text-center">
          <Button type="primary" onClick={navigateToQuestionnaire}>
            Open Questionnaire <RightCircleOutlined />
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default OpportunityDetails;
