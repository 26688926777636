import React, { useEffect, useState, useRef } from "react";
import {
  Dropdown,
  Table,
  Menu,
  Button,
  Space,
  Breadcrumb,
  Row,
  Col,
  Typography,
  Modal,
  Form,
  Tooltip,
  Input,
  message,
  Result,
  Tag,
  Badge,
  Avatar,
} from "antd";
import Highlighter from "react-highlight-words";
import { Auth, Hub } from "aws-amplify";
import axios from "axios";
import {
  AlertFilled,
  BookTwoTone,
  DownOutlined,
  EyeTwoTone,
  FieldTimeOutlined,
  SaveTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import BreadCrumbs from "../BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { routes } from "../../util/constants";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { get } from "firebase/database";
import AddDocumentForm from "../Forms/AddDocumentForm";
import AddContributorForm from "../Forms/AddContributorForm";

const Opportunity = (props) => {
  const [userEmail, setUserEmail] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [opportunity, setOpportunity] = useState({});
  const [allOpportunities, setAllOpportunities] = useState([]);
  const [actionDisabled, setActionDisabled] = useState(true);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [qnaLoading, setQnaLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isQnaDisabled, setisQnaDisabled] = useState(true);
  const [createdOpportunity, setCreatedOpportunity] = useState({});
  const [isAddDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isContributorModalOpen, setContributorModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [userRole, setUserRole] = useState("");
  const [actionsForSR, setActionsForSR] = useState([]);
  const searchInput = useRef(null);
  const [fields, setFields] = useState({
    emailError: null,
    title: "",
    description: "",
    source_requestor: "",

    status: "DRAFT",

    client_name: "",
    client_email: "",
  });
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    authenticateUser();
  }, []);

  useEffect(() => {
    if (
      fields.title &&
      // fields.description &&
      fields.client_name &&
      fields.client_email
    ) {
      setisQnaDisabled(false);
    } else {
      setisQnaDisabled(true);
    }
  }, [
    fields.client_email,
    fields.client_name,
    fields.title,
    fields.description,
  ]);
  useEffect(() => {
    console.log("value of opportunity changed", opportunity);
    let items = [];

    items.push({
      label: <a onClick={naviagteToAddDocumentModal}>Add Document</a>,
    });
    items.push({
      label: (
        <a
          onClick={() => {
            navigateToContributors(opportunity);
          }}
        >
          View Contributors
        </a>
      ),
    });
    items.push({
      label: <a onClick={naviagteToAddContributorModal}>Add Contributor</a>,
    });
    items.push({
      label: (
        <a
          onClick={() => {
            navigateToBTGProposal(opportunity);
          }}
        >
          View BTG Proposal
        </a>
      ),
    });
    let items_2 = items.filter(
      (e) => e.label.props.children !== "View BTG Proposal"
    );
    console.log("itemss 222", items_2);
    setActionsForSR(items_2);

    setDropdownItems(items);
  }, [opportunity]);
  const showCreateOpportunityModal = () => {
    setIsModalOpen(true);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const navigateToBTGProposal = (opp) => {
    navigate(routes.BTG_PROPOSAL_FORM, {
      state: {
        record: opp,
      },
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const authenticateUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    console.log("AccessToken====>", user);

    if (user && user.username) {
      let userEmail = user.username.split("azuread_")[1];
      setUserEmail(userEmail);
      localStorage.setItem("userEmail", userEmail);
      getAllOpportunities(userEmail);
      getUserDetails(userEmail);
    }
  };
  const getUserDetails = async (email) => {
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/user/byid";
    const params = {
      email: email,
    };
    try {
      let res = await axios.get(url, { params });
      console.log("response from get user details", res);
      const role = res.data.roleName;
      console.log("roleeeeeeeeeee", role);
      localStorage.setItem("roleName", role);
      setUserRole(res.data.roleName);
    } catch (error) {
      console.log("error from get user details", error);
    }
  };
  const getAllOpportunities = async (userEmail) => {
    setLoading(true);
    const url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/opportunity/getopportunitybyemail";
    const params = {
      emailId: userEmail,
    };
    let res = {};

    try {
      res = await axios.get(url, { params });
      console.log("resonse from get all opp", res);
    } catch (err) {
      console.log("error from get all opp", err);
    }

    setAllOpportunities(res.data);
    // let items = [];

    // items.push({
    //   label: <a onClick={naviagteToAddDocumentModal}>Add Document</a>,
    // });
    // items.push({
    //   label: (
    //     <a
    //       onClick={() => {
    //         console.log("opp on click", opportunity);
    //         navigateToContributors(opportunity);
    //       }}
    //     >
    //       View Contributors
    //     </a>
    //   ),
    // });
    // items.push({
    //   label: <a onClick={naviagteToAddContributorModal}>Add Contributor</a>,
    // });
    // items.push({
    //   label: <a>Add BTG Proposal</a>,
    // });
    // setDropdownItems(items);
    setLoading(false);
  };

  const naviagteToAddDocumentModal = () => {
    console.log("entered add doc", selectedRow);
    setIsDocumentModalOpen(true);
  };
  const handleIsDocumentModalCancel = () => {
    setIsDocumentModalOpen(false);
  };

  const naviagteToAddContributorModal = () => {
    console.log("entered add contri", selectedRow);
    setContributorModalOpen(true);
  };
  const handleContributorModalCancel = () => {
    setContributorModalOpen(false);
  };
  const navigateToContributors = (opp) => {
    // let record = opportunity;

    console.log("inside navigate to contri", opp);
    navigate(routes.CONTRIBUTORS, {
      state: {
        record: opp,
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      setSelectedRow(selectedRows);

      setActionDisabled(false);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const navigateToEstimations = (record) => {
    navigate(routes.ESTIMATION, {
      state: {
        record,
      },
    });
  };
  const navigateToDetails = (record) => {
    navigate(routes.OPPORTUNITY_DETAILS, {
      state: {
        record,
      },
    });
  };

  const navigateToQuestionnaire = async () => {
    // navigate(routes.QUESTIONNAIRE, {
    //   state: { createdOpportunity },
    // });
    setQnaLoading(true);
    // saveOpportunityDraft();
    let url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/opportunity";
    if ((fields.title, fields.client_email, fields.client_name)) {
      let params = {
        name: fields.title,
        description: fields.description,
        clientName: fields.client_name,
        clientEmail: fields.client_email,

        salesEmail: userEmail,
      };
      try {
        let res = await axios.post(url, params);
        let record = res.data.body;

        console.log("response from create opp", res);
        // setSaveLoading(false);
        getAllOpportunities(userEmail);
        message.success("Opportunity saved as draft");
        // setIsModalOpen(false);
        setQnaLoading(false);
        navigate(routes.QUESTIONNAIRE, {
          state: { record },
        });
        // setQnaLoading(false);
      } catch (error) {
        console.log("error from create opp", error);
      }
    }
  };

  const navigateToDocuments = (record) => {
    navigate(routes.DOCUMENTS, {
      state: {
        record,
      },
    });
  };

  const actionButton = (opp) => {
    console.log("#opp", opp);
    setActionDisabled(false);
    setOpportunity(opp);

    // navigateToContributors(opp);
  };

  const saveOpportunityDraft = async () => {
    console.log("entered save opp");
    setSaveLoading(true);
    // setQnaLoading(true);
    let url =
      "https://5kjewx1ta0.execute-api.ap-south-1.amazonaws.com/dev/opportunity";
    if ((fields.title, fields.client_email, fields.client_name)) {
      let params = {
        name: fields.title,
        description: fields.description,
        clientName: fields.client_name,
        clientEmail: fields.client_email,

        salesEmail: userEmail,
      };
      try {
        let res = await axios.post(url, params);

        console.log("response from create opp", res.data.body);
        setCreatedOpportunity(res.data.body);
        setSaveLoading(false);
        getAllOpportunities(userEmail);
        message.success("Opportunity created");

        setIsModalOpen(false);

        // setQnaLoading(false);
      } catch (error) {
        console.log("error from create opp", error);
      }
    } else {
      message.error("Please enter all the required information");
      setSaveLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFields({ ...fields, [name]: value });
  };

  const chooseAction = (shouldNavigate) => {
    console.log("should navigatee", shouldNavigate);
  };
  const columns = [
    {
      title: "Opportunity",
      dataIndex: "",
      key: "name",
      ...getColumnSearchProps("name"),
      width: 100,
      render: (text, record, index) => {
        return (
          <Typography.Text
            className="underline-effect"
            onClick={() => {
              navigateToDetails(record);
            }}
          >
            {record?.name}
          </Typography.Text>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "statusId",
      // key: "statusId",
      ...getColumnSearchProps("statusId"),
      render: (record) => {
        let color = "";
        if (record === "APPROVED") color = "green";
        if (record === "CURRENTLY_WITH_BTG") color = "purple";
        if (record === "ADDED_CNTBR") color = "geekblue";
        if (record === "ADDED_ESTIMATION") color = "cyan";
        if (record === "DRAFT") color = "gold";
        if (record === "ASSIGNED_BTG_SA") color = "blue";
        if (record === "SUBMITTED") color = "lime";
        if (record === "REJECTED") color = "red";
        return (
          <>
            {" "}
            <Tag color={color}>{record}</Tag>
          </>
        );
      },
      width: 100,
    },
    {
      title: "Created At",
      dataIndex: "createdDate",
      width: 100,
      render: (record) => <>{moment(record).format("MMMM Do YYYY")}</>,
    },
    {
      title: "Document",
      dataIndex: "",
      key: "",
      width: 50,
      align: "center",
      render: (text, record, index) => {
        return (
          <Badge
            size="small"
            count={record.documents.length}
            style={{ marginTop: "-17px", marginRight: "-13px" }}
            // color="#adadad"
            color="#0d6210"
          >
            <div
              type="button"
              onClick={() => {
                navigateToDocuments(record);
              }}
              style={{ fontSize: "17px" }}
            >
              {" "}
              <SaveTwoTone twoToneColor="#08cf72" />
            </div>{" "}
          </Badge>
        );
      },
    },
    {
      title: "Estimation",
      dataIndex: "",
      width: 50,
      align: "center",
      render: (text, record, index) => {
        return (
          <>
            <Badge
              size="small"
              count={record.estimation}
              style={{ marginTop: "-17px", marginRight: "-13px" }}
              // color="#adadad"
              color="#000099"
            >
              <div
                type="button"
                onClick={() => {
                  navigateToEstimations(record);
                }}
                style={{ fontSize: "17px" }}
              >
                <FieldTimeOutlined style={{ color: "#007bff" }} />
              </div>
            </Badge>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="card-body ">
        <h1 className="page-title">Opportunities</h1>
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row ">
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <div className="d-flex  justify-content-end mx-0 ">
          <Dropdown
            disabled={actionDisabled}
            overlay=<Menu
              items={
                userRole === "Sales Representative"
                  ? actionsForSR
                  : dropdownItems
              }
            ></Menu>
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              {/* <Tooltip
                title={actionDisabled ? "Select a opportunity" : null}
                onOpenChange={actionDisabled}
              > */}
              <Button type="primary mx-4 " disabled={actionDisabled}>
                <Space>
                  Actions
                  <DownOutlined className="action-Button mt-2" />
                </Space>
              </Button>
              {/* </Tooltip> */}
            </a>
          </Dropdown>
          {userRole === "Sales Representative" && (
            <Button
              // type="primary"
              className=" "
              onClick={showCreateOpportunityModal}
              // style={{
              //   visibility: userRole !== "Sales Representative" ? "hidden" : "",
              // }}
            >
              <Space>Create Opportunity</Space>
            </Button>
          )}

          <Modal
            open={isAddDocumentModalOpen}
            // onOk={handleOk}
            onCancel={handleIsDocumentModalCancel}
            title="Add Document"
            style={{ top: 20 }}
            width={650}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <AddDocumentForm
              record={opportunity}
              getLatestList={handleIsDocumentModalCancel}
            />
          </Modal>
          <Modal
            open={isContributorModalOpen}
            // onOk={handleOk}
            onCancel={handleContributorModalCancel}
            title="Add Contibutor"
            style={{ top: 20 }}
            width={650}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <AddContributorForm
              // record={selectedRow[0]}
              record={opportunity}
              getLatestList={handleContributorModalCancel}
            />
          </Modal>
          <Modal
            open={isModalOpen}
            // onOk={handleOk}
            onCancel={handleModalCancel}
            title="Create Opportunity"
            style={{ top: 60 }}
            width={650}
            height={650}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              name="Create Opportunity"
              className="Create Opportunity"
              form={form}
              // onKeyDown={onKeyDownSubmit}
              // onFinish={saveOpportunityDraft}
              // onFinish={() => {
              //   saveOpportunityDraft();
              //   // navigateToQuestionnaire();
              // }}
              // onFinish={() => {
              //   chooseAction();
              // }}
              initialValues={{
                remember: true,
              }}
            >
              <Row gutter={[8, 8]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip title="Enter Opportunity Title">
                    <Form.Item
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter title!",
                        },
                      ]}
                    >
                      <Input
                        autoFocus
                        placeholder="Enter Title"
                        name="title"
                        value={fields.title}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip title="Enter Client Name">
                    <Form.Item
                      name="client_name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Client Name!",
                        },
                      ]}
                    >
                      <Input
                        autoFocus
                        placeholder="Enter Client Name"
                        name="client_name"
                        value={fields.client_name}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip title="Enter Client Email">
                    <Form.Item
                      name="client_email"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Client Email!",
                        },
                      ]}
                    >
                      <Input
                        autoFocus
                        placeholder="Enter Client Email"
                        name="client_email"
                        value={fields.client_email}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip title="Source Requester">
                    <Form.Item name="source_requestor">
                      <Input
                        autoFocus
                        name="source_requestor"
                        defaultValue={userEmail}
                        // value={fields.source_requestor}
                        onChange={handleChange}
                        disabled
                        readOnly={true}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>

              <Row
                style={{ marginTop: "10px" }}

                // style={{ marginTop: fields.emailError ? "-16px" : null }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Tooltip title="Enter Description">
                    <TextArea
                      autoFocus
                      placeholder="Type Description..."
                      name="description"
                      value={fields.description}
                      onChange={handleChange}
                      rows={4}
                    />
                  </Tooltip>
                </Col>
              </Row>

              <Form.Item style={{ float: "right", marginTop: "10px" }}>
                <Button
                  type="primary"
                  style={{ margin: "0 10px 0 0" }}
                  htmlType="submit"
                  className="ok-modal"
                  onClick={saveOpportunityDraft}
                  disabled={isQnaDisabled}
                  loading={saveLoading}
                >
                  Save Draft
                </Button>
                <Button
                  type="primary"
                  // htmlType="submit"
                  // className="ok-modal"
                  // onSubmit={navigateToQuestionnaire}
                  onClick={navigateToQuestionnaire}
                  loading={qnaLoading}
                  disabled={isQnaDisabled}
                >
                  Go to questionnaire
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
        <div className="justify-content-end mx-5">
          <div className="card  mx-20 my-3 pt-20  ">
            {/* {pageLoading && (
              <div className="d-flex justify-content-center">
                <Spin size="medium" tip="Loading..."></Spin>
              </div>
            )} */}
            {/* {!pageLoading && ( */}
            <Table
              rowSelection={{
                type: "radio",
                // ...rowSelection,
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedRowKeys(selectedRowKeys);

                  actionButton(selectedRows[0]);
                },
              }}
              loading={loading}
              scroll={{ y: 400 }}
              rowKey="opportunityId"
              columns={columns}
              dataSource={allOpportunities}
              // className="table-striped-rows"
            />
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Opportunity;
